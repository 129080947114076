import React from "react";

class LoadItems extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <textarea className="textarea"
                cols="50"
                placeholder="Wklej Przedmioty ze Zbrojowni tutaj, moga byc wszystkie ;)"
                rows="20"
                onChange={(event) => this.props.func(event.target.value)}>

            </textarea>
        );
    }
}

export default LoadItems;
