const ITEM = [
    {'k':"Z",'v':'Zbroja'},
    {'k':"H",'v':'Helm'},
    {'k':"S",'v':'Spodnie'},
    {'k':"A",'v':'Amulet'},
    {'k':"P",'v':'Pierścień'},
    {'k':"1H",'v':'Broń 1H'},
    {'k':"2H",'v':'Broń 2H'},
];

export default ITEM
