import React from "react";
import ItemType from "./ItemType";

class Tab1 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            itemSelected: [-1,-1,-1]
        }

        this.itemPref = this.itemPref.bind(this);
        this.itemBase = this.itemBase.bind(this);
        this.itemSuf = this.itemSuf.bind(this);
    }

    log() {
        //console.log(this.state)
    }

    itemPref(id) {
        let itemSelected = this.state.itemSelected;
        itemSelected[0] = id;
        this.setState({itemSelected: itemSelected}, this.log);

        this.props.func(itemSelected);
    }

    itemBase(id) {
        let itemSelected = this.state.itemSelected;
        itemSelected[1] = id;
        this.setState({itemSelected: itemSelected}, this.log);

        this.props.func(itemSelected);
    }

    itemSuf(id) {
        let itemSelected = this.state.itemSelected;
        itemSelected[2] = id;
        this.setState({itemSelected: itemSelected}, this.log);

        this.props.func(itemSelected);
    }

    render() {
        let className = this.props.display ? 'cell_body destination-item-tab-content' : 'hide';

        let pref = [];
        if (this.props.item) {
            pref = this.props.item.PX.map((v,k) => {
                return (
                    <ItemType selected={(parseInt(this.state.itemSelected[0]) === k)} func={this.itemPref} name={v} key={k} id={k} />
                )
            });
        }

        let base = [];
        if (this.props.item) {
            base = this.props.item.P.map((v,k) => {
                return (
                    <ItemType selected={(parseInt(this.state.itemSelected[1]) === k)} func={this.itemBase} name={v} key={k} id={k} />
                )
            });
        }

        let suf = [];
        if (this.props.item) {
            suf = this.props.item.SX.map((v,k) => {
                return (
                    <ItemType selected={(parseInt(this.state.itemSelected[2]) === k)} func={this.itemSuf} name={v} key={k} id={k} />
                )
            });
        }


        return (
            <div className={className}>
                <div>
                    <ul className="item_prop2">
                        {pref}
                    </ul>
                </div>
                <div>
                    <ul className="item_prop2">
                        {base}
                    </ul>
                </div>
                <div>
                    <ul className="item_prop2">
                        {suf}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Tab1;

