let BAZA15 = []
let BAZA_REV15 = []

BAZA15['S'] = {};
BAZA15['S']['PX'] = [];
BAZA15['S']['SX'] = [];
BAZA15['S']['P'] = [];
BAZA15['Z'] = {};
BAZA15['Z']['PX'] = [];
BAZA15['Z']['SX'] = [];
BAZA15['Z']['P'] = [];
BAZA15['H'] = {};
BAZA15['H']['PX'] = [];
BAZA15['H']['SX'] = [];
BAZA15['H']['P'] = [];
BAZA15['A'] = {};
BAZA15['A']['PX'] = [];
BAZA15['A']['SX'] = [];
BAZA15['A']['P'] = [];
BAZA15['P'] = {};
BAZA15['P']['PX'] = [];
BAZA15['P']['SX'] = [];
BAZA15['P']['P'] = [];
BAZA15['1H'] = {};
BAZA15['1H']['PX'] = [];
BAZA15['1H']['SX'] = [];
BAZA15['1H']['P'] = [];
BAZA15['2H'] = {};
BAZA15['2H']['PX'] = [];
BAZA15['2H']['SX'] = [];
BAZA15['2H']['P'] = [];

BAZA_REV15['S'] = {};
BAZA_REV15['S']['PX'] = [];
BAZA_REV15['S']['SX'] = [];
BAZA_REV15['S']['P'] = [];
BAZA_REV15['Z'] = {};
BAZA_REV15['Z']['PX'] = [];
BAZA_REV15['Z']['SX'] = [];
BAZA_REV15['Z']['P'] = [];
BAZA_REV15['H'] = {};
BAZA_REV15['H']['PX'] = [];
BAZA_REV15['H']['SX'] = [];
BAZA_REV15['H']['P'] = [];
BAZA_REV15['A'] = {};
BAZA_REV15['A']['PX'] = [];
BAZA_REV15['A']['SX'] = [];
BAZA_REV15['A']['P'] = [];
BAZA_REV15['P'] = {};
BAZA_REV15['P']['PX'] = [];
BAZA_REV15['P']['SX'] = [];
BAZA_REV15['P']['P'] = [];
BAZA_REV15['1H'] = {};
BAZA_REV15['1H']['PX'] = [];
BAZA_REV15['1H']['SX'] = [];
BAZA_REV15['1H']['P'] = [];
BAZA_REV15['2H'] = {};
BAZA_REV15['2H']['PX'] = [];
BAZA_REV15['2H']['SX'] = [];
BAZA_REV15['2H']['P'] = [];

/*ITEM["Z"]='Zbroja';
ITEM["H"]='Helm';
ITEM["S"]='Spodnie';
ITEM["A"]='Amulet';
ITEM["P"]='Pierścień';
ITEM["1H"]='Broń 1H';
ITEM["2H"]='Broń 2H';*/

BAZA15['S']['PX'][0] = 'Krótkie';     BAZA_REV15['S']['PX']['Krótkie'] = 0;    BAZA_REV15['S']['PX']['Krótka'] = 0; BAZA_REV15['S']['PX']['Krótki'] = 0;
BAZA15['S']['PX'][1] = 'Pikowane';    BAZA_REV15['S']['PX']['Pikowane'] = 1;    BAZA_REV15['S']['PX']['Pikowana'] = 1; BAZA_REV15['S']['PX']['Pikowany'] = 1;
BAZA15['S']['PX'][2] = 'Lekkie';      BAZA_REV15['S']['PX']['Lekkie'] = 2;    BAZA_REV15['S']['PX']['Lekka'] = 2;BAZA_REV15['S']['PX']['Lekki'] = 2;
BAZA15['S']['PX'][3] = 'Wzmocnione';    BAZA_REV15['S']['PX']['Wzmocnione'] = 3;    BAZA_REV15['S']['PX']['Wzmocniona'] = 3;BAZA_REV15['S']['PX']['Wzmocniony'] = 3;
BAZA15['S']['PX'][4] = 'Aksamitne';    BAZA_REV15['S']['PX']['Aksamitne'] = 4;    BAZA_REV15['S']['PX']['Aksamitna'] = 4;BAZA_REV15['S']['PX']['Aksamitny'] = 4;
BAZA15['S']['PX'][5] = 'Ćwiekowane';    BAZA_REV15['S']['PX']['Ćwiekowane'] = 5;    BAZA_REV15['S']['PX']['Ćwiekowana'] = 5;BAZA_REV15['S']['PX']['Ćwiekowany'] = 5;
BAZA15['S']['PX'][6] = 'Kuloodporne';    BAZA_REV15['S']['PX']['Kuloodporne'] = 6;  BAZA_REV15['S']['PX']['Kuloodporna'] = 6;BAZA_REV15['S']['PX']['Kuloodporny'] = 6;
BAZA15['S']['PX'][7] = 'Giętkie';    BAZA_REV15['S']['PX']['Giętkie'] = 7;    BAZA_REV15['S']['PX']['Giętka'] = 7;BAZA_REV15['S']['PX']['Giętki'] = 7;
BAZA15['S']['PX'][8] = 'Kolcze';      BAZA_REV15['S']['PX']['Kolcze'] = 8;    BAZA_REV15['S']['PX']['Kolcza'] = 8;BAZA_REV15['S']['PX']['Kolczy'] = 8;
BAZA15['S']['PX'][9] = 'Szamańskie';    BAZA_REV15['S']['PX']['Szamańskie'] = 9;    BAZA_REV15['S']['PX']['Szamańska'] = 9;BAZA_REV15['S']['PX']['Szamański'] = 9;
BAZA15['S']['PX'][10] = 'Krwawe';    BAZA_REV15['S']['PX']['Krwawe'] = 10;    BAZA_REV15['S']['PX']['Krwawa'] = 10;BAZA_REV15['S']['PX']['Krwawy'] = 10;
BAZA15['S']['PX'][11] = 'Elfie';      BAZA_REV15['S']['PX']['Elfie'] = 11;    BAZA_REV15['S']['PX']['Elfia'] = 11;BAZA_REV15['S']['PX']['Elfi'] = 11;
BAZA15['S']['PX'][12] = 'Tygrysie';    BAZA_REV15['S']['PX']['Tygrysie'] = 12;    BAZA_REV15['S']['PX']['Tygrysia'] = 12;BAZA_REV15['S']['PX']['Tygrysi'] = 12;
BAZA15['S']['PX'][13] = 'Pancerne';    BAZA_REV15['S']['PX']['Pancerne'] = 13;    BAZA_REV15['S']['PX']['Pancerna'] = 13;BAZA_REV15['S']['PX']['Pancerny'] = 13;
BAZA15['S']['PX'][14] = 'Kompozytowe';    BAZA_REV15['S']['PX']['Kompozytowe'] = 14;  BAZA_REV15['S']['PX']['Kompozytowa'] = 14;BAZA_REV15['S']['PX']['Kompozytowy'] = 14;
BAZA15['S']['PX'][15] = 'Runiczne';    BAZA_REV15['S']['PX']['Runiczne'] = 15;    BAZA_REV15['S']['PX']['Runiczna'] = 15;BAZA_REV15['S']['PX']['Runiczny'] = 15;
BAZA15['S']['PX'][16] = 'Śmiercionośne';    BAZA_REV15['S']['PX']['Śmiercionośne'] = 16;  BAZA_REV15['S']['PX']['Śmiercionośna'] = 16;BAZA_REV15['S']['PX']['Śmiercionośny'] = 16;


BAZA15['S']['P'][0] = 'Szorty';       BAZA_REV15['S']['P']['Szorty'] = 0;
BAZA15['S']['P'][1] = 'Spodnie';      BAZA_REV15['S']['P']['Spodnie'] = 1;
BAZA15['S']['P'][2] = 'Spódnica';    BAZA_REV15['S']['P']['Spódnica'] = 2;
BAZA15['S']['P'][3] = 'Kilt';      BAZA_REV15['S']['P']['Kilt'] = 3;


BAZA15['S']['SX'][0] = 'Rzezimieszka';     BAZA_REV15['S']['SX']['Rzezimieszka'] = 0;
BAZA15['S']['SX'][1] = 'Przemytnika';     BAZA_REV15['S']['SX']['Przemytnika'] = 1;
BAZA15['S']['SX'][2] = 'Narkomana';     BAZA_REV15['S']['SX']['Narkomana'] = 2;
BAZA15['S']['SX'][3] = 'Siłacza';     BAZA_REV15['S']['SX']['Siłacza'] = 3;
BAZA15['S']['SX'][4] = 'Cichych ruchów';   BAZA_REV15['S']['SX']['Cichych Ruchów'] = 4;
BAZA15['S']['SX'][5] = 'Uników';     BAZA_REV15['S']['SX']['Uników'] = 5;
BAZA15['S']['SX'][6] = 'Skrytości';     BAZA_REV15['S']['SX']['Skrytości'] = 6;
BAZA15['S']['SX'][7] = 'Słońca';     BAZA_REV15['S']['SX']['Słońca'] = 7;
BAZA15['S']['SX'][8] = 'Handlarza Bronią';   BAZA_REV15['S']['SX']['Handlarza Bronią'] = 8;
BAZA15['S']['SX'][9] = 'Pasterza';     BAZA_REV15['S']['SX']['Pasterza'] = 9;
BAZA15['S']['SX'][10] = 'Łowcy Cieni';     BAZA_REV15['S']['SX']['Łowcy Cieni'] = 10;
BAZA15['S']['SX'][11] = 'Węża';       BAZA_REV15['S']['SX']['Węża'] = 11;
BAZA15['S']['SX'][12] = 'Inków';     BAZA_REV15['S']['SX']['Inków'] = 12;
BAZA15['S']['SX'][13] = 'Tropiciela';     BAZA_REV15['S']['SX']['Tropiciela'] = 13;
BAZA15['S']['SX'][14] = 'Nocy';       BAZA_REV15['S']['SX']['Nocy'] = 14;




/*Zbroje Prefix:
1.Wzmocniona 2.Ćwiekowana 3.Władcza 4.Lekka 5.Łuskowa 6.Płytowa 7.Bojowa 8.Giętka 9.Krwawa 10.Łowiecka 11.Szamańska 12.Kuloodporna 13.Tygrysia 14.Elfia 15.Runiczna 16.Śmiercionośna*/
BAZA15['Z']['PX'][0] = 'Wzmocniona';   BAZA_REV15['Z']['PX']['Wzmocniona'] = 0;    BAZA_REV15['Z']['PX']['Wzmocniony'] = 0;
BAZA15['Z']['PX'][1] = 'Ćwiekowana';   BAZA_REV15['Z']['PX']['Ćwiekowana'] = 1;    BAZA_REV15['Z']['PX']['Ćwiekowany'] = 1;
BAZA15['Z']['PX'][2] = 'Władcza';   BAZA_REV15['Z']['PX']['Władcza'] = 2;    BAZA_REV15['Z']['PX']['Władczy'] = 2;
BAZA15['Z']['PX'][3] = 'Lekka';     BAZA_REV15['Z']['PX']['Lekka'] = 3;    BAZA_REV15['Z']['PX']['Lekki'] = 3;
BAZA15['Z']['PX'][4] = 'Łuskowa';   BAZA_REV15['Z']['PX']['Łuskowa'] = 4;    BAZA_REV15['Z']['PX']['Łuskowy'] = 4;
BAZA15['Z']['PX'][5] = 'Płytowa';   BAZA_REV15['Z']['PX']['Płytowa'] = 5;    BAZA_REV15['Z']['PX']['Płytowy'] = 5;
BAZA15['Z']['PX'][6] = 'Bojowa';   BAZA_REV15['Z']['PX']['Bojowa'] = 6;    BAZA_REV15['Z']['PX']['Bojowy'] = 6;
BAZA15['Z']['PX'][7] = 'Giętka';   BAZA_REV15['Z']['PX']['Giętka'] = 7;    BAZA_REV15['Z']['PX']['Giętki'] = 7;
BAZA15['Z']['PX'][8] = 'Krwawa';   BAZA_REV15['Z']['PX']['Krwawa'] = 8;    BAZA_REV15['Z']['PX']['Krwawy'] = 8;
BAZA15['Z']['PX'][9] = 'Łowiecka';   BAZA_REV15['Z']['PX']['Łowiecka'] = 9;    BAZA_REV15['Z']['PX']['Łowiecki'] = 9;
BAZA15['Z']['PX'][10] = 'Szamańska';   BAZA_REV15['Z']['PX']['Szamańska'] = 10;    BAZA_REV15['Z']['PX']['Szamański'] = 10;
BAZA15['Z']['PX'][11] = 'Kuloodporna';   BAZA_REV15['Z']['PX']['Kuloodporna'] = 11;  BAZA_REV15['Z']['PX']['Kuloodporny'] = 11;
BAZA15['Z']['PX'][12] = 'Tygrysia';   BAZA_REV15['Z']['PX']['Tygrysia'] = 12;    BAZA_REV15['Z']['PX']['Tygrysi'] = 12;
BAZA15['Z']['PX'][13] = 'Elfia';   BAZA_REV15['Z']['PX']['Elfia'] = 13;    BAZA_REV15['Z']['PX']['Elfi'] = 13;
BAZA15['Z']['PX'][14] = 'Runiczna';   BAZA_REV15['Z']['PX']['Runiczna'] = 14;    BAZA_REV15['Z']['PX']['Runiczny'] = 14;
BAZA15['Z']['PX'][15] = 'Śmiercionośna'; BAZA_REV15['Z']['PX']['Śmiercionośna'] = 15;  BAZA_REV15['Z']['PX']['Śmiercionośny'] = 15;

/*Zbroje:
1.Koszulka 2.Kurtka 3.Marynarka 4.Kamizelka 5.Gorset 6.Peleryna 7.Smoking 8.Kolczuga 9.Zbroja warstwowa 10.Pełna Zbroja
*/
BAZA15['Z']['P'][0] = 'Koszulka';   BAZA_REV15['Z']['P']['Koszulka'] = 0;
BAZA15['Z']['P'][1] = 'Kurtka';     BAZA_REV15['Z']['P']['Kurtka'] = 1;
BAZA15['Z']['P'][2] = 'Marynarka';   BAZA_REV15['Z']['P']['Marynarka'] = 2;
BAZA15['Z']['P'][3] = 'Kamizelka';   BAZA_REV15['Z']['P']['Kamizelka'] = 3;
BAZA15['Z']['P'][4] = 'Gorset';     BAZA_REV15['Z']['P']['Gorset'] = 4;
BAZA15['Z']['P'][5] = 'Peleryna';   BAZA_REV15['Z']['P']['Peleryna'] = 5;
BAZA15['Z']['P'][6] = 'Smoking';   BAZA_REV15['Z']['P']['Smoking'] = 6;
BAZA15['Z']['P'][7] = 'Kolczuga';   BAZA_REV15['Z']['P']['Kolczuga'] = 7;
BAZA15['Z']['P'][8] = 'Zbroja_warstwowa';BAZA_REV15['Z']['P']['Zbroja_warstwowa'] = 8;
BAZA15['Z']['P'][9] = 'Pełna_zbroja';   BAZA_REV15['Z']['P']['Pełna_zbroja'] = 9;

/*Zbroje Suffix:
1.Złodzieja 2.Adepta 3.Strażnika 4.Siłacza 5.Narkomana 6.Szermierza 7.Zabójcy 8.Gwardzisty 9.Kobry 10.Skorupy żółwia 11.Uników 12.Grabieżcy 13.Mistrza 14.Adrenaliny 15.Centuriona 16.Odporności 
17.Kaliguli 18.Siewcy śmierci 19.Szybkości 20.Orchidei
*/
BAZA15['Z']['SX'][0] = 'Złodzieja';   BAZA_REV15['Z']['SX']['Złodzieja'] = 0;
BAZA15['Z']['SX'][1] = 'Adepta';   BAZA_REV15['Z']['SX']['Adepta'] = 1;
BAZA15['Z']['SX'][2] = 'Strażnika';   BAZA_REV15['Z']['SX']['Strażnika'] = 2;
BAZA15['Z']['SX'][3] = 'Siłacza';   BAZA_REV15['Z']['SX']['Siłacza'] = 3;
BAZA15['Z']['SX'][4] = 'Narkomana';   BAZA_REV15['Z']['SX']['Narkomana'] = 4;
BAZA15['Z']['SX'][5] = 'Szermierza';   BAZA_REV15['Z']['SX']['Szermierza'] = 5;
BAZA15['Z']['SX'][6] = 'Zabójcy';   BAZA_REV15['Z']['SX']['Zabójcy'] = 6;
BAZA15['Z']['SX'][7] = 'Gwardzisty';   BAZA_REV15['Z']['SX']['Gwardzisty'] = 7;
BAZA15['Z']['SX'][8] = 'Kobry';     BAZA_REV15['Z']['SX']['Kobry'] = 8;
BAZA15['Z']['SX'][9] = 'Skorupy żółwia'; BAZA_REV15['Z']['SX']['Skorupy żółwia'] = 9;
BAZA15['Z']['SX'][10] = 'Uników';   BAZA_REV15['Z']['SX']['Uników'] = 10;
BAZA15['Z']['SX'][11] = 'Grabieżcy';   BAZA_REV15['Z']['SX']['Grabieżcy'] = 11;
BAZA15['Z']['SX'][12] = 'Mistrza';   BAZA_REV15['Z']['SX']['Mistrza'] = 12;
BAZA15['Z']['SX'][13] = 'Adrenaliny';   BAZA_REV15['Z']['SX']['Adrenaliny'] = 13;
BAZA15['Z']['SX'][14] = 'Centuriona';   BAZA_REV15['Z']['SX']['Centuriona'] = 14;
BAZA15['Z']['SX'][15] = 'Odporności';   BAZA_REV15['Z']['SX']['Odporności'] = 15;
BAZA15['Z']['SX'][16] = 'Kaliguli';   BAZA_REV15['Z']['SX']['Kaliguli'] = 16;
BAZA15['Z']['SX'][17] = 'Siewcy Śmierci';BAZA_REV15['Z']['SX']['Siewcy Śmierci'] = 17;
BAZA15['Z']['SX'][18] = 'Szybkości';   BAZA_REV15['Z']['SX']['Szybkości'] = 18;
BAZA15['Z']['SX'][19] = 'Orchidei';   BAZA_REV15['Z']['SX']['Orchidei'] = 19;


/*Hełmy Prefix:
1.Utwardzany 2.Wzmocniony 3.Pomocny 4.Ozdobny 5.Elegancki 6.Rogaty 7.Złośliwy 8.Leniwy 9.Śmiercionośny 10.Bojowy 11.Magnetyczny 12.Krwawy 13.Kunsztowny 14.Kuloodporny 
15.Szamański 16.Tygrysi 17.Szturmowy 18.Runiczny 19.Rytualny 
*/
BAZA15['H']['PX'][0] = 'Utwardzany';   BAZA_REV15['H']['PX']['Utwardzany'] = 0;    BAZA_REV15['H']['PX']['Utwardzana'] = 0;
BAZA15['H']['PX'][1] = 'Wzmocniony';   BAZA_REV15['H']['PX']['Wzmocniony'] = 1;    BAZA_REV15['H']['PX']['Wzmocniona'] = 1;
BAZA15['H']['PX'][2] = 'Pomocny';   BAZA_REV15['H']['PX']['Pomocny'] = 2;      BAZA_REV15['H']['PX']['Pomocna'] = 2;
BAZA15['H']['PX'][3] = 'Ozdobny';   BAZA_REV15['H']['PX']['Ozdobny'] = 3;      BAZA_REV15['H']['PX']['Ozdobna'] = 3;
BAZA15['H']['PX'][4] = 'Elegancki';   BAZA_REV15['H']['PX']['Elegancki'] = 4;    BAZA_REV15['H']['PX']['Elegancka'] = 4;
BAZA15['H']['PX'][5] = 'Rogaty';   BAZA_REV15['H']['PX']['Rogaty'] = 5;      BAZA_REV15['H']['PX']['Rogata'] = 5;
BAZA15['H']['PX'][6] = 'Złośliwy';   BAZA_REV15['H']['PX']['Złośliwy'] = 6;      BAZA_REV15['H']['PX']['Złośliwa'] = 6;
BAZA15['H']['PX'][7] = 'Leniwy';   BAZA_REV15['H']['PX']['Leniwy'] = 7;      BAZA_REV15['H']['PX']['Leniwa'] = 7;
BAZA15['H']['PX'][8] = 'Śmiercionośny';   BAZA_REV15['H']['PX']['Śmiercionośny'] = 8;  BAZA_REV15['H']['PX']['Śmiercionośna'] = 8;
BAZA15['H']['PX'][9] = 'Bojowy';   BAZA_REV15['H']['PX']['Bojowy'] = 9;      BAZA_REV15['H']['PX']['Bojowa'] = 9;
BAZA15['H']['PX'][10] = 'Magnetyczny';   BAZA_REV15['H']['PX']['Magnetyczny'] = 10;    BAZA_REV15['H']['PX']['Magnetyczna'] = 10;
BAZA15['H']['PX'][11] = 'Krwawy';   BAZA_REV15['H']['PX']['Krwawy'] = 11;      BAZA_REV15['H']['PX']['Krwawa'] = 11;
BAZA15['H']['PX'][12] = 'Kunsztowny';   BAZA_REV15['H']['PX']['Kunsztowny'] = 12;    BAZA_REV15['H']['PX']['Kunsztowna'] = 12;
BAZA15['H']['PX'][13] = 'Kuloodporny';   BAZA_REV15['H']['PX']['Kuloodporny'] = 13;    BAZA_REV15['H']['PX']['Kuloodporna'] = 13;
BAZA15['H']['PX'][14] = 'Szamański';   BAZA_REV15['H']['PX']['Szamański'] = 14;    BAZA_REV15['H']['PX']['Szamańska'] = 14;
BAZA15['H']['PX'][15] = 'Tygrysi';   BAZA_REV15['H']['PX']['Tygrysi'] = 15;      BAZA_REV15['H']['PX']['Tygrysia'] = 15;
BAZA15['H']['PX'][16] = 'Szturmowy';   BAZA_REV15['H']['PX']['Szturmowy'] = 16;    BAZA_REV15['H']['PX']['Szturmowa'] = 16;
BAZA15['H']['PX'][17] = 'Runiczny';   BAZA_REV15['H']['PX']['Runiczny'] = 17;    BAZA_REV15['H']['PX']['Runiczna'] = 17;
BAZA15['H']['PX'][18] = 'Rytualny';   BAZA_REV15['H']['PX']['Rytualny'] = 18;    BAZA_REV15['H']['PX']['Rytualna'] = 18;


/*Hełmy Baza:
1.Czapka 2.Kask 3.Hełm 4.Maska 5.Obręcz 6.Kominiarka 7.Kapelusz 8.Opaska 9.Bandana 10.Korona
*/
BAZA15['H']['P'][0] = 'Czapka';     BAZA_REV15['H']['P']['Czapka'] = 0;
BAZA15['H']['P'][1] = 'Kask';     BAZA_REV15['H']['P']['Kask'] = 1;
BAZA15['H']['P'][2] = 'Hełm';     BAZA_REV15['H']['P']['Hełm'] = 2;
BAZA15['H']['P'][3] = 'Maska';     BAZA_REV15['H']['P']['Maska'] = 3;
BAZA15['H']['P'][4] = 'Obręcz';     BAZA_REV15['H']['P']['Obręcz'] = 4;
BAZA15['H']['P'][5] = 'Kominiarka';   BAZA_REV15['H']['P']['Kominiarka'] = 5;
BAZA15['H']['P'][6] = 'Kapelusz';   BAZA_REV15['H']['P']['Kapelusz'] = 6;
BAZA15['H']['P'][7] = 'Opaska';     BAZA_REV15['H']['P']['Opaska'] = 7;
BAZA15['H']['P'][8] = 'Bandana';   BAZA_REV15['H']['P']['Bandana'] = 8;
BAZA15['H']['P'][9] = 'Korona';     BAZA_REV15['H']['P']['Korona'] = 9;

/*Hełmy Suffix:
1.Podróżnika 2.Przezorności 3.Wytrzymałości 4.Pasterza 5.Narkomana 6.Ochrony 7.Zmysłów 8.Wieszcza 9.Kary 10.Gladiatora 11.Krwi 12.Skorupy żółwia 13.Słońca 14.Adrenaliny 
15.Prekognicji 16.Smoczej łuski 17.Mocy 18.Magii
*/
BAZA15['H']['SX'][0] = 'Podróżnika';   BAZA_REV15['H']['SX']['Podróżnika'] = 0;
BAZA15['H']['SX'][1] = 'Przezorności';   BAZA_REV15['H']['SX']['Przezorności'] = 1;
BAZA15['H']['SX'][2] = 'Wytrzymałości';   BAZA_REV15['H']['SX']['Wytrzymałości'] = 2;
BAZA15['H']['SX'][3] = 'Pasterza';   BAZA_REV15['H']['SX']['Pasterza'] = 3;
BAZA15['H']['SX'][4] = 'Narkomana';   BAZA_REV15['H']['SX']['Narkomana'] = 4;
BAZA15['H']['SX'][5] = 'Ochrony';   BAZA_REV15['H']['SX']['Ochrony'] = 5;
BAZA15['H']['SX'][6] = 'Zmysłów';   BAZA_REV15['H']['SX']['Zmysłów'] = 6;
BAZA15['H']['SX'][7] = 'Wieszcza';   BAZA_REV15['H']['SX']['Wieszcza'] = 7;
BAZA15['H']['SX'][8] = 'Kary';     BAZA_REV15['H']['SX']['Kary'] = 8;
BAZA15['H']['SX'][9] = 'Gladiatora';   BAZA_REV15['H']['SX']['Gladiatora'] = 9;
BAZA15['H']['SX'][10] = 'Krwi';     BAZA_REV15['H']['SX']['Krwi'] = 10;
BAZA15['H']['SX'][11] = 'Skorupy żółwia';BAZA_REV15['H']['SX']['Skorupy żółwia'] = 11;
BAZA15['H']['SX'][12] = 'Słońca';   BAZA_REV15['H']['SX']['Słońca'] = 12;
BAZA15['H']['SX'][13] = 'Adrenaliny';   BAZA_REV15['H']['SX']['Adrenaliny'] = 13;
BAZA15['H']['SX'][14] = 'Prekognicji';   BAZA_REV15['H']['SX']['Prekognicji'] = 14;
BAZA15['H']['SX'][15] = 'Smoczej Łuski'; BAZA_REV15['H']['SX']['Smoczej Łuski'] = 15;
BAZA15['H']['SX'][16] = 'Mocy';     BAZA_REV15['H']['SX']['Mocy'] = 16;
BAZA15['H']['SX'][17] = 'Magii';   BAZA_REV15['H']['SX']['Magii'] = 17;

/*
Biżuteria Prefix:
1.Miedziany 2.Srebrny 3.Szmaragdoowy 4.Złoty 5.Platynowy 6.Tytanowy 7.Rubinowy 8.Dystyngowany 9.Przebiegły 10.Niedźwiedzi 11.Twardy 12.Gwiezdny 13.Elastyczny 
14.Kardynalski 15.Nekromancki 16.Archaiczny 17.Hipnotyczny 18.Tańczący 19.Zwierzęcy 20.Diamentowy 21.Mściwy 22.Spaczony 23.Plastikowy 24.Zdradziecki 25.Słoneczny 
26.Pajęczy 27.Jastrzębi 28.Czarny
*/
BAZA15['A']['PX'][0] = 'Miedziany';      BAZA_REV15['A']['PX']['Miedziany'] = 0;      BAZA_REV15['A']['PX']['Miedziana'] = 0;
BAZA15['A']['PX'][1] = 'Srebrny';        BAZA_REV15['A']['PX']['Srebrny'] = 1;        BAZA_REV15['A']['PX']['Srebrna'] = 1;
BAZA15['A']['PX'][2] = 'Szmaragdowy';    BAZA_REV15['A']['PX']['Szmaragdowy'] = 2;    BAZA_REV15['A']['PX']['Szmaragdowa'] = 2;
BAZA15['A']['PX'][3] = 'Złoty';          BAZA_REV15['A']['PX']['Złoty'] = 3;          BAZA_REV15['A']['PX']['Złota'] = 3;
BAZA15['A']['PX'][4] = 'Platynowy';      BAZA_REV15['A']['PX']['Platynowy'] = 4;      BAZA_REV15['A']['PX']['Platynowa'] = 4;
BAZA15['A']['PX'][5] = 'Tytanowy';       BAZA_REV15['A']['PX']['Tytanowy'] = 5;       BAZA_REV15['A']['PX']['Tytanowa'] = 5;
BAZA15['A']['PX'][6] = 'Rubinowy';       BAZA_REV15['A']['PX']['Rubinowy'] = 6;       BAZA_REV15['A']['PX']['Rubinowa'] = 6;
BAZA15['A']['PX'][7] = 'Dystyngowany';   BAZA_REV15['A']['PX']['Dystyngowany'] = 7;   BAZA_REV15['A']['PX']['Dystyngowana'] = 7;
BAZA15['A']['PX'][8] = 'Przebiegły';     BAZA_REV15['A']['PX']['Przebiegły'] = 8;     BAZA_REV15['A']['PX']['Przebiegła'] = 8;
BAZA15['A']['PX'][9] = 'Niedźwiedzi';    BAZA_REV15['A']['PX']['Niedźwiedzi'] = 9;    BAZA_REV15['A']['PX']['Niedźwiedzia'] = 9;
BAZA15['A']['PX'][10] = 'Twardy';        BAZA_REV15['A']['PX']['Twardy'] = 10;        BAZA_REV15['A']['PX']['Twarda'] = 10;
BAZA15['A']['PX'][11] = 'Gwiezdny';      BAZA_REV15['A']['PX']['Gwiezdny'] = 11;      BAZA_REV15['A']['PX']['Gwiezdna'] = 11;
BAZA15['A']['PX'][12] = 'Elastyczny';    BAZA_REV15['A']['PX']['Elastyczny'] = 12;    BAZA_REV15['A']['PX']['Elastyczna'] = 12;
BAZA15['A']['PX'][13] = 'Kardynalski';   BAZA_REV15['A']['PX']['Kardynalski'] = 13;   BAZA_REV15['A']['PX']['Kardynalska'] = 13;
BAZA15['A']['PX'][14] = 'Nekromancki';   BAZA_REV15['A']['PX']['Nekromancki'] = 14;   BAZA_REV15['A']['PX']['Nekromancka'] = 14;
BAZA15['A']['PX'][15] = 'Archaiczny';    BAZA_REV15['A']['PX']['Archaiczny'] = 15;    BAZA_REV15['A']['PX']['Archaiczna'] = 15;
BAZA15['A']['PX'][16] = 'Hipnotyczny';   BAZA_REV15['A']['PX']['Hipnotyczny'] = 16;   BAZA_REV15['A']['PX']['Hipnotyczna'] = 16;
BAZA15['A']['PX'][17] = 'Tańczący';      BAZA_REV15['A']['PX']['Tańczący'] = 17;      BAZA_REV15['A']['PX']['Tańcząca'] = 17;
BAZA15['A']['PX'][18] = 'Zwierzęcy';     BAZA_REV15['A']['PX']['Zwierzęcy'] = 18;     BAZA_REV15['A']['PX']['Zwierzęca'] = 18;
BAZA15['A']['PX'][19] = 'Diamentowy';    BAZA_REV15['A']['PX']['Diamentowy'] = 19;    BAZA_REV15['A']['PX']['Diamentowa'] = 19;
BAZA15['A']['PX'][20] = 'Mściwy';        BAZA_REV15['A']['PX']['Mściwy'] = 20;        BAZA_REV15['A']['PX']['Mściwa'] = 20;
BAZA15['A']['PX'][21] = 'Spaczony';      BAZA_REV15['A']['PX']['Spaczony'] = 21;      BAZA_REV15['A']['PX']['Spaczona'] = 21;
BAZA15['A']['PX'][22] = 'Plastikowy';    BAZA_REV15['A']['PX']['Plastikowy'] = 22;    BAZA_REV15['A']['PX']['Plastikowa'] = 22;
BAZA15['A']['PX'][23] = 'Zdradziecki';   BAZA_REV15['A']['PX']['Zdradziecki'] = 23;   BAZA_REV15['A']['PX']['Zdradziecka'] = 23;
BAZA15['A']['PX'][24] = 'Słoneczny';     BAZA_REV15['A']['PX']['Słoneczny'] = 24;     BAZA_REV15['A']['PX']['Słoneczna'] = 24;
BAZA15['A']['PX'][25] = 'Pajęczy';       BAZA_REV15['A']['PX']['Pajęczy'] = 25;       BAZA_REV15['A']['PX']['Pajęcza'] = 25;
BAZA15['A']['PX'][26] = 'Jastrzębi';     BAZA_REV15['A']['PX']['Jastrzębi'] = 26;     BAZA_REV15['A']['PX']['Jastrzębia'] = 26;
BAZA15['A']['PX'][27] = 'Czarny';        BAZA_REV15['A']['PX']['Czarny'] = 27;        BAZA_REV15['A']['PX']['Czarna'] = 27;

BAZA15['P']['PX'] = BAZA15['A']['PX']; BAZA_REV15['P']['PX'] = BAZA_REV15['A']['PX'];

/*
Pierścienie Podstawa:
1.Pierścień 2.Bransoleta 3.Sygnet
*/
BAZA15['P']['P'][0] = 'Pierścień'; BAZA_REV15['P']['P']['Pierścień'] = 0;
BAZA15['P']['P'][1] = 'Bransoleta'; BAZA_REV15['P']['P']['Bransoleta'] = 1;
BAZA15['P']['P'][2] = 'Sygnet'; BAZA_REV15['P']['P']['Sygnet'] = 2;
/*
Pierścienie Suffix:
1.Występku 2.Urody 3.Władzy 4.Siły 5.Geniuszu 6.Mądrości 7.Twardej Skóry 8.Wilkołaka 9.Sztuki 10.Celności 11.Młodości 12.Lisa 13.Szczęścia 14.Krwi 15.Nietoperza 
16.Koncentracji 17.Lewitacji 18.Przebiegłości 19.Szaleńca 20.Łatwości
*/
BAZA15['P']['SX'][0] = 'Występku';       BAZA_REV15['P']['SX']['Występku'] = 0;
BAZA15['P']['SX'][1] = 'Urody';          BAZA_REV15['P']['SX']['Urody'] = 1;
BAZA15['P']['SX'][2] = 'Władzy';         BAZA_REV15['P']['SX']['Władzy'] = 2;
BAZA15['P']['SX'][3] = 'Siły';           BAZA_REV15['P']['SX']['Siły'] = 3;
BAZA15['P']['SX'][4] = 'Geniuszu';       BAZA_REV15['P']['SX']['Geniuszu'] = 4;
BAZA15['P']['SX'][5] = 'Mądrości';       BAZA_REV15['P']['SX']['Mądrości'] = 5;
BAZA15['P']['SX'][6] = 'Twardej Skóry';  BAZA_REV15['P']['SX']['Twardej Skóry'] = 6;
BAZA15['P']['SX'][7] = 'Wilkołaka';      BAZA_REV15['P']['SX']['Wilkołaka'] = 7;
BAZA15['P']['SX'][8] = 'Sztuki';         BAZA_REV15['P']['SX']['Sztuki'] = 8;
BAZA15['P']['SX'][9] = 'Celności';       BAZA_REV15['P']['SX']['Celności'] = 9;
BAZA15['P']['SX'][10] = 'Młodości';      BAZA_REV15['P']['SX']['Młodości'] = 10;
BAZA15['P']['SX'][11] = 'Lisa';          BAZA_REV15['P']['SX']['Lisa'] = 11;
BAZA15['P']['SX'][12] = 'Szczęścia';     BAZA_REV15['P']['SX']['Szczęścia'] = 12;
BAZA15['P']['SX'][13] = 'Krwi';          BAZA_REV15['P']['SX']['Krwi'] = 13;
BAZA15['P']['SX'][14] = 'Nietoperza';    BAZA_REV15['P']['SX']['Nietoperza'] = 14;
BAZA15['P']['SX'][15] = 'Koncentracji';  BAZA_REV15['P']['SX']['Koncentracji'] = 15;
BAZA15['P']['SX'][16] = 'Lewitacji';     BAZA_REV15['P']['SX']['Lewitacji'] = 16;
BAZA15['P']['SX'][17] = 'Przebiegłości'; BAZA_REV15['P']['SX']['Przebiegłości'] = 17;
BAZA15['P']['SX'][18] = 'Szaleńca';      BAZA_REV15['P']['SX']['Szaleńca'] = 18;
BAZA15['P']['SX'][19] = 'Łatwości';      BAZA_REV15['P']['SX']['Łatwości'] = 19;


/*Amulety Podstawa:
1.Amulet 2.Naszyjnik 3.Łańcuch 4.Apaszka 5.Krawat
*/
BAZA15['A']['P'][0] = 'Naszyjnik';   BAZA_REV15['A']['P']['Naszyjnik'] = 0;
BAZA15['A']['P'][1] = 'Amulet';      BAZA_REV15['A']['P']['Amulet'] = 1;
BAZA15['A']['P'][2] = 'Łańcuch';     BAZA_REV15['A']['P']['Łańcuch'] = 2;
BAZA15['A']['P'][3] = 'Apaszka';     BAZA_REV15['A']['P']['Apaszka'] = 3;
BAZA15['A']['P'][4] = 'Krawat';      BAZA_REV15['A']['P']['Krawat'] = 4;


/*Amulety Suffix:
1.Występku 2.Urody 3.Władzy 4.Geniuszu 5.Siły 6.Mądrości 7.Twardej Skóry 8.Pielgrzyma 9.Wilkołaka 10.Celności 11.Sztuki 12.Młodości 13.Szczęścia 14.Krwi 15.Zdolności 
16.Koncentracji 17.Lewitacji 18.Przebiegłości 19.Szaleńca 20.Łatwości
*/
BAZA15['A']['SX'][0] = 'Występku';       BAZA_REV15['A']['SX']['Występku'] = 0;
BAZA15['A']['SX'][1] = 'Urody';          BAZA_REV15['A']['SX']['Urody'] = 1;
BAZA15['A']['SX'][2] = 'Władzy';         BAZA_REV15['A']['SX']['Władzy'] = 2;
BAZA15['A']['SX'][3] = 'Geniuszu';       BAZA_REV15['A']['SX']['Geniuszu'] = 3;
BAZA15['A']['SX'][4] = 'Siły';           BAZA_REV15['A']['SX']['Siły'] = 4;
BAZA15['A']['SX'][5] = 'Mądrości';       BAZA_REV15['A']['SX']['Mądrości'] = 5;
BAZA15['A']['SX'][6] = 'Twardej Skóry';  BAZA_REV15['A']['SX']['Twardej Skóry'] = 6;
BAZA15['A']['SX'][7] = 'Pielgrzyma';     BAZA_REV15['A']['SX']['Pielgrzyma'] = 7;
BAZA15['A']['SX'][8] = 'Wilkołaka';      BAZA_REV15['A']['SX']['Wilkołaka'] = 8;
BAZA15['A']['SX'][9] = 'Celności';       BAZA_REV15['A']['SX']['Celności'] = 9;
BAZA15['A']['SX'][10] = 'Sztuki';        BAZA_REV15['A']['SX']['Sztuki'] = 10;
BAZA15['A']['SX'][11] = 'Młodości';      BAZA_REV15['A']['SX']['Młodości'] = 11;
BAZA15['A']['SX'][12] = 'Szczęścia';     BAZA_REV15['A']['SX']['Szczęścia'] = 12;
BAZA15['A']['SX'][13] = 'Krwi';          BAZA_REV15['A']['SX']['Krwi'] = 13;
BAZA15['A']['SX'][14] = 'Zdolności';     BAZA_REV15['A']['SX']['Zdolności'] = 14;
BAZA15['A']['SX'][15] = 'Koncentracji';  BAZA_REV15['A']['SX']['Koncentracji'] = 15;
BAZA15['A']['SX'][16] = 'Lewitacji';     BAZA_REV15['A']['SX']['Lewitacji'] = 16;
BAZA15['A']['SX'][17] = 'Przebiegłości'; BAZA_REV15['A']['SX']['Przebiegłości'] = 17;
BAZA15['A']['SX'][18] = 'Szaleńca';      BAZA_REV15['A']['SX']['Szaleńca'] = 18;
BAZA15['A']['SX'][19] = 'Łatwości';      BAZA_REV15['A']['SX']['Łatwości'] = 19;

/*
Biała 1h Prefix:
1.Ostry 2.Zębaty 3.Kościany 4.Wzmacniajacy 5.Kryształowy 6.Mistyczny 7.Lekki 8.Okrutny 9.Przyjacielski 10.Kąsający 11.Opiekuńczy 12.Świecący 13.Jadowity 14.Zabójczy 15.Zatruty 
16.Przeklęty 17.Zwinny 18.Antyczny 19.Szybki 20.Demoniczny
*/
BAZA15['1H']['PX'][0] = 'Ostry';         BAZA_REV15['1H']['PX']['Ostry'] = 0;        BAZA_REV15['1H']['PX']['Ostra'] = 0;          BAZA_REV15['1H']['PX']['Ostre'] = 0;
BAZA15['1H']['PX'][1] = 'Zębaty';        BAZA_REV15['1H']['PX']['Zębaty'] = 1;       BAZA_REV15['1H']['PX']['Zębata'] = 1;         BAZA_REV15['1H']['PX']['Zębate'] = 1;
BAZA15['1H']['PX'][2] = 'Kościany';      BAZA_REV15['1H']['PX']['Kościany'] = 2;     BAZA_REV15['1H']['PX']['Kościana'] = 2;       BAZA_REV15['1H']['PX']['Kościane'] = 2;
BAZA15['1H']['PX'][3] = 'Wzmacniający';  BAZA_REV15['1H']['PX']['Wzmacniający'] = 3; BAZA_REV15['1H']['PX']['Wzmacniająca'] = 3;   BAZA_REV15['1H']['PX']['Wzmacniające'] = 3;
BAZA15['1H']['PX'][4] = 'Kryształowy';   BAZA_REV15['1H']['PX']['Kryształowy'] = 4;  BAZA_REV15['1H']['PX']['Kryształowa'] = 4;    BAZA_REV15['1H']['PX']['Kryształowe'] = 4;
BAZA15['1H']['PX'][5] = 'Mistyczny';     BAZA_REV15['1H']['PX']['Mistyczny'] = 5;    BAZA_REV15['1H']['PX']['Mistyczna'] = 5;      BAZA_REV15['1H']['PX']['Mistyczne'] = 5;
BAZA15['1H']['PX'][6] = 'Lekki';         BAZA_REV15['1H']['PX']['Lekki'] = 6;        BAZA_REV15['1H']['PX']['Lekka'] = 6;          BAZA_REV15['1H']['PX']['Lekkie'] = 6;
BAZA15['1H']['PX'][7] = 'Okrutny';       BAZA_REV15['1H']['PX']['Okrutny'] = 7;      BAZA_REV15['1H']['PX']['Okrutna'] = 7;        BAZA_REV15['1H']['PX']['Okrutne'] = 7;
BAZA15['1H']['PX'][8] = 'Przyjacielski'; BAZA_REV15['1H']['PX']['Przyjacielski'] = 8;BAZA_REV15['1H']['PX']['Przyjacielska'] = 8;  BAZA_REV15['1H']['PX']['Przyjacielskie'] = 8;
BAZA15['1H']['PX'][9] = 'Kąsający';      BAZA_REV15['1H']['PX']['Kąsający'] = 9;     BAZA_REV15['1H']['PX']['Kąsająca'] = 9;       BAZA_REV15['1H']['PX']['Kąsające'] = 9;
BAZA15['1H']['PX'][10] = 'Opiekuńczy';   BAZA_REV15['1H']['PX']['Opiekuńczy'] = 10;  BAZA_REV15['1H']['PX']['Opiekuńcza'] = 10;    BAZA_REV15['1H']['PX']['Opiekuńcze'] = 10;
BAZA15['1H']['PX'][11] = 'Świecący';     BAZA_REV15['1H']['PX']['Świecący'] = 11;    BAZA_REV15['1H']['PX']['Świecąca'] = 11;      BAZA_REV15['1H']['PX']['Świecące'] = 11;
BAZA15['1H']['PX'][12] = 'Jadowity';     BAZA_REV15['1H']['PX']['Jadowity'] = 12;    BAZA_REV15['1H']['PX']['Jadowita'] = 12;      BAZA_REV15['1H']['PX']['Jadowite'] = 12;
BAZA15['1H']['PX'][13] = 'Zabójczy';     BAZA_REV15['1H']['PX']['Zabójczy'] = 13;    BAZA_REV15['1H']['PX']['Zabójcza'] = 13;      BAZA_REV15['1H']['PX']['Zabójcze'] = 13;
BAZA15['1H']['PX'][14] = 'Zatruty';      BAZA_REV15['1H']['PX']['Zatruty'] = 14;     BAZA_REV15['1H']['PX']['Zatruta'] = 14;       BAZA_REV15['1H']['PX']['Zatrute'] = 14;
BAZA15['1H']['PX'][15] = 'Przeklęty';    BAZA_REV15['1H']['PX']['Przeklęty'] = 15;   BAZA_REV15['1H']['PX']['Przeklęta'] = 15;     BAZA_REV15['1H']['PX']['Przeklęte'] = 15;
BAZA15['1H']['PX'][16] = 'Zwinny';       BAZA_REV15['1H']['PX']['Zwinny'] = 16;      BAZA_REV15['1H']['PX']['Zwinna'] = 16;        BAZA_REV15['1H']['PX']['Zwinne'] = 16;
BAZA15['1H']['PX'][17] = 'Antyczny';     BAZA_REV15['1H']['PX']['Antyczny'] = 17;    BAZA_REV15['1H']['PX']['Antyczna'] = 17;      BAZA_REV15['1H']['PX']['Antyczne'] = 17;
BAZA15['1H']['PX'][18] = 'Szybki';       BAZA_REV15['1H']['PX']['Szybki'] = 18;      BAZA_REV15['1H']['PX']['Szybka'] = 18;        BAZA_REV15['1H']['PX']['Szybkie'] = 18;
BAZA15['1H']['PX'][19] = 'Demoniczny';   BAZA_REV15['1H']['PX']['Demoniczny'] = 19;  BAZA_REV15['1H']['PX']['Demoniczna'] = 19;    BAZA_REV15['1H']['PX']['Demoniczne'] = 19;

/*
Biała 1h
1.Pałka 2.Nóż 3.Sztylet 4.Kastet 5.Miecz 6.Rapier 7.Kama 8.Topór 9.Wakizashi 10.Pięść Niebios
*/
BAZA15['1H']['P'][0] = 'Pałka';          BAZA_REV15['1H']['P']['Pałka'] = 0;
BAZA15['1H']['P'][1] = 'Nóż';            BAZA_REV15['1H']['P']['Nóż'] = 1;
BAZA15['1H']['P'][2] = 'Sztylet';        BAZA_REV15['1H']['P']['Sztylet'] = 2;
BAZA15['1H']['P'][3] = 'Kastet';         BAZA_REV15['1H']['P']['Kastet'] = 3;
BAZA15['1H']['P'][4] = 'Miecz';          BAZA_REV15['1H']['P']['Miecz'] = 4;
BAZA15['1H']['P'][5] = 'Rapier';         BAZA_REV15['1H']['P']['Rapier'] = 5;
BAZA15['1H']['P'][6] = 'Kama';           BAZA_REV15['1H']['P']['Kama'] = 6;
BAZA15['1H']['P'][7] = 'Topór';          BAZA_REV15['1H']['P']['Topór'] = 7;
BAZA15['1H']['P'][8] = 'Wakizashi';      BAZA_REV15['1H']['P']['Wakizashi'] = 8;
BAZA15['1H']['P'][9] = 'Pięść_Niebios';  BAZA_REV15['1H']['P']['Pięść_Niebios'] = 9;

/*
Biała 1h Suffix:
1.Dowódcy 2.Sekty 3.Bólu 4.Władzy 5.Zwinności 6.Mocy 7.Zarazy 8.Odwagi 9.Trafienia 10.Przodków 11.Zdobywcy 12.Zemsty 13.Kontuzji 14.Męstwa 15.Precyzji 16.Krwi 17.Podkowy 
18.Samobójcy 19.Drakuli 20.Biegłości 21.Klanu 22.Imperatora
*/
BAZA15['1H']['SX'][0] = 'Dowódcy';     BAZA_REV15['1H']['SX']['Dowódcy'] = 0;
BAZA15['1H']['SX'][1] = 'Sekty';       BAZA_REV15['1H']['SX']['Sekty'] = 1;
BAZA15['1H']['SX'][2] = 'Bólu';        BAZA_REV15['1H']['SX']['Bólu'] = 2;
BAZA15['1H']['SX'][3] = 'Władzy';      BAZA_REV15['1H']['SX']['Władzy'] = 3;
BAZA15['1H']['SX'][4] = 'Zwinności';   BAZA_REV15['1H']['SX']['Zwinności'] = 4;
BAZA15['1H']['SX'][5] = 'Mocy';        BAZA_REV15['1H']['SX']['Mocy'] = 5;
BAZA15['1H']['SX'][6] = 'Zarazy';      BAZA_REV15['1H']['SX']['Zarazy'] = 6;
BAZA15['1H']['SX'][7] = 'Odwagi';      BAZA_REV15['1H']['SX']['Odwagi'] = 7;
BAZA15['1H']['SX'][8] = 'Trafienia';   BAZA_REV15['1H']['SX']['Trafienia'] = 8;
BAZA15['1H']['SX'][9] = 'Przodków';    BAZA_REV15['1H']['SX']['Przodków'] = 9;
BAZA15['1H']['SX'][10] = 'Zdobywcy';   BAZA_REV15['1H']['SX']['Zdobywcy'] = 10;
BAZA15['1H']['SX'][11] = 'Zemsty';     BAZA_REV15['1H']['SX']['Zemsty'] = 11;
BAZA15['1H']['SX'][12] = 'Kontuzji';   BAZA_REV15['1H']['SX']['Kontuzji'] = 12;
BAZA15['1H']['SX'][13] = 'Męstwa';     BAZA_REV15['1H']['SX']['Męstwa'] = 13;
BAZA15['1H']['SX'][14] = 'Precyzji';   BAZA_REV15['1H']['SX']['Precyzji'] = 14;
BAZA15['1H']['SX'][15] = 'Krwi';       BAZA_REV15['1H']['SX']['Krwi'] = 15;
BAZA15['1H']['SX'][16] = 'Podkowy';    BAZA_REV15['1H']['SX']['Podkowy'] = 16;
BAZA15['1H']['SX'][17] = 'Samobójcy';  BAZA_REV15['1H']['SX']['Samobójcy'] = 17;
BAZA15['1H']['SX'][18] = 'Drakuli';    BAZA_REV15['1H']['SX']['Drakuli'] = 18;
BAZA15['1H']['SX'][19] = 'Biegłości';  BAZA_REV15['1H']['SX']['Biegłości'] = 19;
BAZA15['1H']['SX'][20] = 'Klanu';      BAZA_REV15['1H']['SX']['Klanu'] = 20;
BAZA15['1H']['SX'][21] = 'Imperatora'; BAZA_REV15['1H']['SX']['Imperatora'] = 21;



/*
Biała 2h Prefix:
1.Kosztowna 2.Ostra 3.Kryształowa 4.Zębata 5.Szeroka 6.Okrutna 7.Mistyczna 8.Wzmacniająca 9.Kąsajaca 10.Lekka 11.Ciężka 12.Zatruta 13.Napromieniowana 14.Świecąca 15.Opiekuńcza 
16.Jadowita 17.Zabójcza 18.Przeklęta 19.Zwinna 20.Antyczna 21.Demoniczna
*/
BAZA15['2H']['PX'][0] = 'Kosztowna';         BAZA_REV15['2H']['PX']['Kosztowna'] = 0;         BAZA_REV15['2H']['PX']['Kosztowny'] = 0;
BAZA15['2H']['PX'][1] = 'Ostra';             BAZA_REV15['2H']['PX']['Ostra'] = 1;             BAZA_REV15['2H']['PX']['Ostry'] = 1;
BAZA15['2H']['PX'][2] = 'Kryształowa';       BAZA_REV15['2H']['PX']['Kryształowa'] = 2;       BAZA_REV15['2H']['PX']['Kryształowy'] = 2;
BAZA15['2H']['PX'][3] = 'Zębata';            BAZA_REV15['2H']['PX']['Zębata'] = 3;            BAZA_REV15['2H']['PX']['Zębaty'] = 3;
BAZA15['2H']['PX'][4] = 'Szeroka';           BAZA_REV15['2H']['PX']['Szeroka'] = 4;           BAZA_REV15['2H']['PX']['Szeroki'] = 4;
BAZA15['2H']['PX'][5] = 'Okrutna';           BAZA_REV15['2H']['PX']['Okrutna'] = 5;           BAZA_REV15['2H']['PX']['Okrutny'] = 5;
BAZA15['2H']['PX'][6] = 'Mistyczna';         BAZA_REV15['2H']['PX']['Mistyczna'] = 6;         BAZA_REV15['2H']['PX']['Mistyczny'] = 6;
BAZA15['2H']['PX'][7] = 'Wzmacniająca';      BAZA_REV15['2H']['PX']['Wzmacniająca'] = 7;      BAZA_REV15['2H']['PX']['Wzmacniający'] = 7;
BAZA15['2H']['PX'][8] = 'Kąsająca';          BAZA_REV15['2H']['PX']['Kąsająca'] = 8;          BAZA_REV15['2H']['PX']['Kąsający'] = 8;
BAZA15['2H']['PX'][9] = 'Lekka';             BAZA_REV15['2H']['PX']['Lekka'] = 9;             BAZA_REV15['2H']['PX']['Lekki'] = 9;
BAZA15['2H']['PX'][10] = 'Ciężka';            BAZA_REV15['2H']['PX']['Ciężka'] = 10;            BAZA_REV15['2H']['PX']['Ciężki'] = 10;
BAZA15['2H']['PX'][11] = 'Zatruta';           BAZA_REV15['2H']['PX']['Zatruta'] = 11;           BAZA_REV15['2H']['PX']['Zatruty'] = 11;
BAZA15['2H']['PX'][12] = 'Napromieniowana';   BAZA_REV15['2H']['PX']['Napromieniowana'] = 12;   BAZA_REV15['2H']['PX']['Napromieniowany'] = 12;
BAZA15['2H']['PX'][13] = 'Świecąca';          BAZA_REV15['2H']['PX']['Świecąca'] = 13;          BAZA_REV15['2H']['PX']['Świecący'] = 13;
BAZA15['2H']['PX'][14] = 'Opiekuńcza';        BAZA_REV15['2H']['PX']['Opiekuńcza'] = 14;        BAZA_REV15['2H']['PX']['Opiekuńczy'] = 14;
BAZA15['2H']['PX'][15] = 'Jadowita';          BAZA_REV15['2H']['PX']['Jadowita'] = 15;          BAZA_REV15['2H']['PX']['Jadowity'] = 15;
BAZA15['2H']['PX'][16] = 'Zabójcza';          BAZA_REV15['2H']['PX']['Zabójcza'] = 16;          BAZA_REV15['2H']['PX']['Zabójczy'] = 16;
BAZA15['2H']['PX'][17] = 'Przeklęta';         BAZA_REV15['2H']['PX']['Przeklęta'] = 17;         BAZA_REV15['2H']['PX']['Przeklęty'] = 17;
BAZA15['2H']['PX'][18] = 'Zwinna';            BAZA_REV15['2H']['PX']['Zwinna'] = 18;            BAZA_REV15['2H']['PX']['Zwinny'] = 18;
BAZA15['2H']['PX'][19] = 'Antyczna';          BAZA_REV15['2H']['PX']['Antyczna'] = 19;          BAZA_REV15['2H']['PX']['Antyczny'] = 19;
BAZA15['2H']['PX'][20] = 'Demoniczna';        BAZA_REV15['2H']['PX']['Demoniczna'] = 20;        BAZA_REV15['2H']['PX']['Demoniczny'] = 20;


/*
Biała 2h
1.Maczuga 2.Łom 3.Miecz Dwuręczny 4.Topór Dwuręczny 5.Korbacz 6.Kosa 7.Pika 8.Halabarda 9.Katana 10.Piła Łańcuchowa
*/
BAZA15['2H']['P'][0] = 'Maczuga';            BAZA_REV15['2H']['P']['Maczuga'] = 0;
BAZA15['2H']['P'][1] = 'Łom';                BAZA_REV15['2H']['P']['Łom'] = 1;
BAZA15['2H']['P'][2] = 'Miecz_Dwuręczny';    BAZA_REV15['2H']['P']['Miecz_Dwuręczny'] = 2;
BAZA15['2H']['P'][3] = 'Topór_Dwuręczny';    BAZA_REV15['2H']['P']['Topór_Dwuręczny'] = 3;
BAZA15['2H']['P'][4] = 'Korbacz';            BAZA_REV15['2H']['P']['Korbacz'] = 4;
BAZA15['2H']['P'][5] = 'Kosa';               BAZA_REV15['2H']['P']['Kosa'] = 5;
BAZA15['2H']['P'][6] = 'Pika';               BAZA_REV15['2H']['P']['Pika'] = 6;
BAZA15['2H']['P'][7] = 'Halabarda';          BAZA_REV15['2H']['P']['Halabarda'] = 7;
BAZA15['2H']['P'][8] = 'Katana';             BAZA_REV15['2H']['P']['Katana'] = 8;
BAZA15['2H']['P'][9] = 'Piła_łańcuchowa';    BAZA_REV15['2H']['P']['Piła_łańcuchowa'] = 9;

/*
Biała 2h Suffix:
1.Zdrady 2.Podstępu 3.Bólu 4.Hazardzisty 5.Ołowiu 6.Mocy 7.Inkwizytora 8.Krwiopijcy 9.Zdobywcy 10.Władzy 
11.Zemsty 12.Zarazy 13.Podkowy 14.Autokraty 15.Krwi 16.Bazyliszka 17.Samobójcy 18.Drakuli
*/
BAZA15['2H']['SX'][0] = 'Zdrady';        BAZA_REV15['2H']['SX']['Zdrady'] = 0;
BAZA15['2H']['SX'][1] = 'Podstępu';      BAZA_REV15['2H']['SX']['Podstępu'] = 1;
BAZA15['2H']['SX'][2] = 'Bólu';          BAZA_REV15['2H']['SX']['Bólu'] = 2;
BAZA15['2H']['SX'][3] = 'Hazardzisty';   BAZA_REV15['2H']['SX']['Hazardzisty'] = 3;
BAZA15['2H']['SX'][4] = 'Ołowiu';        BAZA_REV15['2H']['SX']['Ołowiu'] = 4;
BAZA15['2H']['SX'][5] = 'Mocy';          BAZA_REV15['2H']['SX']['Mocy'] = 5;
BAZA15['2H']['SX'][6] = 'Inkwizytora';   BAZA_REV15['2H']['SX']['Inkwizytora'] = 6;
BAZA15['2H']['SX'][7] = 'Krwiopijcy';    BAZA_REV15['2H']['SX']['Krwiopijcy'] = 7;
BAZA15['2H']['SX'][8] = 'Zdobywcy';      BAZA_REV15['2H']['SX']['Zdobywcy'] = 8;
BAZA15['2H']['SX'][9] = 'Władzy';        BAZA_REV15['2H']['SX']['Władzy'] = 9;
BAZA15['2H']['SX'][10] = 'Zemsty';        BAZA_REV15['2H']['SX']['Zemsty'] = 10;
BAZA15['2H']['SX'][11] = 'Zarazy';        BAZA_REV15['2H']['SX']['Zarazy'] = 11;
BAZA15['2H']['SX'][12] = 'Podkowy';       BAZA_REV15['2H']['SX']['Podkowy'] = 12;
BAZA15['2H']['SX'][13] = 'Autokraty';     BAZA_REV15['2H']['SX']['Autokraty'] = 13;
BAZA15['2H']['SX'][14] = 'Krwi';          BAZA_REV15['2H']['SX']['Krwi'] = 14;
BAZA15['2H']['SX'][15] = 'Bazyliszka';    BAZA_REV15['2H']['SX']['Bazyliszka'] = 15;
BAZA15['2H']['SX'][16] = 'Samobójcy';     BAZA_REV15['2H']['SX']['Samobójcy'] = 16;
BAZA15['2H']['SX'][17] = 'Drakuli';       BAZA_REV15['2H']['SX']['Drakuli'] = 17;

export {
    BAZA15,
    BAZA_REV15
}
