import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import {/*createMuiTheme, MuiThemeProvider, makeStyles, */withStyles} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Simulation from "./Simulation";
//import Radio from "@material-ui/core/Radio";
import LoadedItem from "./LoadedItem";

class Tab3 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            algoritm: [true, true, true],
            iterations: ["100","100","100"],
            progress: [0,0,0],
            simulationRun: false,
            simulationDidRun: false,
            result: {
                "0":{"best": [], "cost":1000, "score":1000, connections:[]},
                "1":{"best": [], "cost":1000, "score":1000, connections:[]},
                "2":{"best": [], "cost":1000, "score":1000, connections:[]}
            },
            BAZA: this.props.BAZA,
            itemTypeValue: this.props.itemTypeValue,
            destinationItem: this.props.destinationItem,
            loadedItemsNumber: this.props.loadedItemsNumber,
            server: this.props.server
        }
        this.selectAlgoritm = this.selectAlgoritm.bind(this);
        this.setIterations = this.setIterations.bind(this);
        this.runSimulation = this.runSimulation.bind(this);
        this.startSimulation = this.startSimulation.bind(this);
        this.validateItemSet = this.validateItemSet.bind(this);
        this.resetSimulation = this.resetSimulation.bind(this);

        this.result = {
            "0":{"best": [], "cost":1000, "score":1000, connections:[]},
            "1":{"best": [], "cost":1000, "score":1000, connections:[]},
            "2":{"best": [], "cost":1000, "score":1000, connections:[]}
        }

        this.Simulation = new Simulation();
        //console.log("Creating Tab 3");
    }

    resetSimulation() {
        this.result = {
            "0":{"best": [], "cost":1000, "score":1000, connections:[]},
            "1":{"best": [], "cost":1000, "score":1000, connections:[]},
            "2":{"best": [], "cost":1000, "score":1000, connections:[]}
        }

        this.setState({
            result: {
                "0": {"best": [], "cost": 1000, "score": 1000, connections: []},
                "1": {"best": [], "cost": 1000, "score": 1000, connections: []},
                "2": {"best": [], "cost": 1000, "score": 1000, connections: []}
            },
            simulationRun: false,
            simulationDidRun: false,
            progress: [0, 0, 0],
        });


        //console.log("RESET SIMULATION");
    }

    startSimulation() {
        this.result = {
            "0":{"best": [], "cost":1000, "score":1000, connections:[]},
            "1":{"best": [], "cost":1000, "score":1000, connections:[]},
            "2":{"best": [], "cost":1000, "score":1000, connections:[]}
        }
        this.setState({
            result: {
                "0": {"best": [], "cost": 1000, "score": 1000, connections: []},
                "1": {"best": [], "cost": 1000, "score": 1000, connections: []},
                "2": {"best": [], "cost": 1000, "score": 1000, connections: []}
            },
            simulationRun: false,
            simulationDidRun: false,
            progress: [0, 0, 0],
        }, this.runSimulation);

    }

    //Nie znajduje dla:
    // Legendarny Kuloodporny Kilt Łowcy Cieni (+1)
    // Legendarne Doskonałe Pancerne Spodnie Łowcy Cieni (+2)
    // Legendarna Doskonała Giętka Spódnica Łowcy Cieni (+2)
    // Legendarne Doskonałe Runiczne Szorty Inków (+5)
    // Legendarne Kuloodporne Szorty Nocy (+1)
    // Legendarna Dobra Kompozytowa Spódnica Węża (+5)
    // Legendarna Dobra Kuloodporna Spódnica Inków (+2)

    //boo mozna tak
        //Leg. kuloodporna spódnica inków + Leg. pancerne spodnie łowcy cieni = Leg. elfi kilt inków
        // Leg. elfi kilt inków + Leg. kompozytowa spódnica węża = Leg. kompozytowy spodnie tropiciela
        // Leg. kompozytowy spodnie tropiciela + Leg. runiczne szorty inków = Leg. śmiercionośna spódnica nocy

    //componentWillReceiveProps(nextProps, any) {
    componentDidUpdate(prevProps, prevState, snapshot) {
        //only clr when key data updated
        if (this.props.BAZA !== prevProps.BAZA ||
            this.props.itemTypeValue !== prevProps.itemTypeValue ||
            this.props.destinationItem !== prevProps.destinationItem ||
            this.props.loadedItemsNumber !== prevProps.loadedItemsNumber ||
            this.props.server !== prevProps.server
        ) {
            this.setState({
                    BAZA: this.props.BAZA,
                    itemTypeValue: this.props.itemTypeValue,
                    destinationItem: this.props.destinationItem,
                    loadedItemsNumber: this.props.loadedItemsNumber,
                    server: this.props.server
            });
            this.resetSimulation();
        }
    }

    // selectType(event) {
    //     this.props.func(parseInt(event.target.id))
    // }

    checkDestinationItem(item) {
        const values = Object.values(item);
        if (values.length === 0) return false;
        for (const val of values) {
            if (val === -1) {
                return false;
            }
        }
        return true;
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    validateItemSet(method, ItemSet, connections, connection_cost) {
        for(let i=0; i<ItemSet.length; i++) {
            let score = this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, ItemSet[i]);
            if (score <= this.result[method].score) {
                //console.log("All connections:" + JSON.stringify(connections));
                let clean_connections = this.cleanup_connections(connections, ItemSet[i]);
                //console.log("Clean connections:" + JSON.stringify(clean_connections));
                if (score < this.result[method].score ||
                    (score === this.result[method].score && clean_connections.cost < this.result[method].cost)
                ) {
                    this.result[method].score = score;
                    this.result[method].cost = clean_connections.cost;
                    this.result[method].best = ItemSet[i];
                    this.result[method].connections = clean_connections.connections;

                    //this.props.usedItems(clean_connections.connections);

                    this.setState({result: this.result});
                }
            }
            if (score === 0) {
                return true;
            }
        }

        return false;
    }


    simulation(method) {
        //console.log("SIMULATION START " + method);
        //method == 0->hybrid | 1->hybrid2-0 | 2->hybrid 2-1

            let ItemSet = [...this.state.loadedItemsNumber];
            let connections = [];
            let connection_cost = 0;
            let connCount = 0;

            let improvementJump = 6;//we start with 3
            let stop_count = 7;

            //console.log("start");
            //console.log(ItemSet.length);
            //console.log("itemSet: " + ItemSet);
            if (method === 1 || method === 2) {
                 let goodConnections;
                 let good_conn;

                 goodConnections = this.Simulation.goodConnections(ItemSet, this.state.BAZA, this.state.itemTypeValue);
                 //console.log("all connections" + goodConnections);
                 //here do the joining based on best connections possible
                 if (method === 1) {
                     good_conn = goodConnections['type1'].concat(goodConnections['type2'], goodConnections['type3']);
                 } else {
                     good_conn = goodConnections['type1'].concat(goodConnections['type2'], goodConnections['type3'], goodConnections['type4']);
                 }

                 //console.log(good_conn);

                 while (good_conn.length > 0) {

                     if(this.validateItemSet(method, ItemSet, connections, connection_cost)) {
                         return true;
                     }

                     //print_r($good_conn);
                     let conn = good_conn[this.getRandomInt(good_conn.length)];
                     //console.log("selected connection");
                     //console.log(conn);
                     connections[connCount] = conn;
                     connCount++;
                     //print_r($conn);
                     let P1 = conn[0];
                     let P2 = conn[1];
                     let res = conn[2];

                     connection_cost +=  P1.reduce((a, b) => a + b, 0) + P2.reduce((a, b) => a + b, 0);
                     //console.log("connection cost 1:");
                     //console.log(connection_cost);
                     // for(let k=0; k<ItemSet.length; k++) {
                     //     if (this.compareItems(ItemSet[k], P1)) {
                     //         ItemSet[k] = res;
                     //     }
                     //     if (this.compareItems(ItemSet[k], P2)) {
                     //         ItemSet.splice(k, 1);
                     //     }
                     // }
                     ItemSet = this.swapItem(ItemSet, res, P1, P2);

                     goodConnections = this.Simulation.goodConnections(ItemSet, this.state.BAZA, this.state.itemTypeValue);
                     //here do the joining based on best connections possible
                     if (method === 1) {
                          good_conn = goodConnections['type1'].concat(goodConnections['type2'], goodConnections['type3']);
                     } else {
                         good_conn = goodConnections['type1'].concat(goodConnections['type2'], goodConnections['type3'], goodConnections['type4']);
                     }
                 }
            }

            if(this.validateItemSet(method, ItemSet, connections, connection_cost)) {
                return true;
            }

            while (ItemSet.length > stop_count) {
                 //Ths is all possibilities
                 let better_connections = [];
                 for (let pos1=0; pos1<ItemSet.length; pos1++) {
                    for (let pos2=pos1+1; pos2<ItemSet.length; pos2++) {
                        //only if output item is better than input item


                            let res = this.Simulation.laczenie(ItemSet[pos1], ItemSet[pos2], this.state.BAZA, this.state.itemTypeValue, this.state.server);
                            //echo(display($BAZA[$TYPE], $ItemsSet[$pos1])."+".display($BAZA[$TYPE], $ItemsSet[$pos2])."=".display($BAZA[$TYPE], $res)." q1:".quality($BAZA[$TYPE], $GOAL, $ItemsSet[$pos1])." q2:".quality($BAZA[$TYPE], $GOAL, $ItemsSet[$pos2])." res:".quality($BAZA[$TYPE], $GOAL, $res)."<br>\n");
                            //if (quality($BAZA[$TYPE], $GOAL, $res)+$improvementJump<min(quality($BAZA[$TYPE], $GOAL, $ItemsSet[$pos1]), quality($BAZA[$TYPE], $GOAL, $ItemsSet[$pos2]))) {
                            let quality_bump = Math.min(
                                this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, ItemSet[pos1]),
                                this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, ItemSet[pos2])
                            )-this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, res);

                            if (quality_bump >= improvementJump) {
                                //echo("accepted<br>\n");
                                let result = [];
                                result[0] = ItemSet[pos1];
                                result[1] = ItemSet[pos2];
                                result[2] = res;
                                better_connections.push(result);
                            }

                    }
                }

                if (better_connections.length === 0) {
                    //$no_better = true;
                    improvementJump--;
                    //echo("\n<br>ImprovementJump:".$improvementJump."\n\n<br><br>");
                    continue;
                }

                //print_r($good_conn);
                let conn = better_connections[this.getRandomInt(better_connections.length)];
                //console.log("selected connection");
                //console.log(conn);
                connections[connCount] = conn;
                connCount++;
                //print_r($conn);
                let P1 = conn[0];
                let P2 = conn[1];
                let res = conn[2];

                connection_cost +=  P1.reduce((a, b) => a + b, 0) + P2.reduce((a, b) => a + b, 0);
                //console.log("connection cost 2:");
                //console.log(connection_cost);

                // for(let k=0; k<ItemSet.length; k++) {
                //     if (this.compareItems(ItemSet[k], P1)) {
                //         ItemSet[k] = res;
                //     }
                //     if (this.compareItems(ItemSet[k], P2)) {
                //         ItemSet.splice(k, 1);
                //     }
                // }

                ItemSet = this.swapItem (ItemSet, res, P1, P2);

                if(this.validateItemSet(method, ItemSet, connections, connection_cost)) {
                    return true;
                }
            }

            //console.log(ItemSet.length);
            //console.log(JSON.stringify(ItemSet));

            //Now we are down to 7 items
            if (this.connectionsRecursive(ItemSet, method, connections, connection_cost)) {
                return true;
            }
    }

    connectionsRecursive(ItemSet, method, connections, connection_cost) {

        //console.log("level: " + ItemSet.length + " connections:" + JSON.stringify(connections));
        //console.log(JSON.stringify(ItemSet));

        if (ItemSet.length === 2) {
            let res = this.Simulation.laczenie(ItemSet[0], ItemSet[1], this.state.BAZA, this.state.itemTypeValue, this.state.server);
            let conn = [ItemSet[0], ItemSet[1], res];
            connections.push(conn);

            connection_cost +=  ItemSet[0].reduce((a, b) => a + b, 0) + ItemSet[1].reduce((a, b) => a + b, 0);

            // for(let k=0; k<ItemSet.length; k++) {
            //     if (this.compareItems(ItemSet[k], ItemSet[1])) {
            //         ItemSet[k] = res;
            //     }
            //     if (this.compareItems(ItemSet[k], ItemSet[0])) {
            //         ItemSet.splice(k, 1);
            //     }
            // }

            ItemSet = this.swapItem (ItemSet, res, ItemSet[0], ItemSet[1]);

            if(this.validateItemSet(method, ItemSet, connections, connection_cost)) {
                return true;
            }

        } else {
            for (let i=0; i < ItemSet.length; i++) {
                for (let j=i+1; j < ItemSet.length; j++) {
                    let res = this.Simulation.laczenie(ItemSet[i], ItemSet[j], this.state.BAZA, this.state.itemTypeValue, this.state.server);

                    let NewItemSet = JSON.parse(JSON.stringify(ItemSet));
                    let NewConnections = JSON.parse(JSON.stringify(connections));

                    //echo("\n".quality($BAZA, $GOAL, $items[$i])." + ".quality($BAZA, $GOAL, $items[$j])." = ".quality($BAZA, $GOAL, $res)."\n ".display($BAZA, $res));
                    if (
                        Math.min(
                            this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, ItemSet[i]),
                            this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, ItemSet[j])
                        ) > this.Simulation.quality(this.state.BAZA[this.state.itemTypeValue], this.state.destinationItem, res)
                    ) {
                    //    let res = this.Simulation.laczenie(ItemSet[i], ItemSet[j], this.state.BAZA, this.state.itemTypeValue);
                        let conn = [NewItemSet[i], NewItemSet[j], res];
                        NewConnections.push(conn);

                        connection_cost +=  NewItemSet[i].reduce((a, b) => a + b, 0) + NewItemSet[j].reduce((a, b) => a + b, 0);

                        // for(let k=0; k<NewItemSet.length; k++) {
                        //     if (this.compareItems(NewItemSet[k], NewItemSet[i])) {
                        //         NewItemSet[k] = res;
                        //     }
                        //     console.log("j: "+j+" k: "+k+" "+JSON.stringify(NewItemSet));
                        //     if (this.compareItems(NewItemSet[k], NewItemSet[j])) {
                        //         NewItemSet.splice(k, 1);
                        //     }
                        // }
                        NewItemSet = this.swapItem (NewItemSet, res, NewItemSet[i], NewItemSet[j]);

                        if(this.validateItemSet(method, NewItemSet, NewConnections, connection_cost)) {
                            return true;
                        }

                        // if (NewItemSet.length === 6) {
                        //     console.log("spawning new fundtion level " + NewItemSet.length);
                        //     console.log(JSON.stringify(NewConnections));
                        //     console.log(JSON.stringify(NewItemSet));
                        // }
                        this.connectionsRecursive(NewItemSet, method, NewConnections, connection_cost);

                    }
                }
            }

        }
    }
    swapItem (ItemSet, res, P1, P2) {
        let length = ItemSet.length;
        for (let k = 0; k < length; k++) {
            //console.log("P1: " + JSON.stringify(P1) + "P2: " + JSON.stringify(P2) + " k: " + k + " " + JSON.stringify(ItemSet));
            if (this.compareItems(ItemSet[k], P1)) {
                ItemSet[k] = res;
            }
            //console.log("j: " + j + " k: " + k + " " + JSON.stringify(ItemSet));
            if (this.compareItems(ItemSet[k], P2)) {
                ItemSet.splice(k, 1);
                length--;
                k--;
            }
            //console.log("Result: " + JSON.stringify(ItemSet));
        }
        return ItemSet;
    }

    compareItems (it1, it2) {
        for (let i=0; i<3; i++) {
            if (it1[i] !== it2[i]) {
                return false;
            }
        }
        return true;
    }

    cleanup_connections (connections, item) {
        let cost = 0;
        let clean_connections = [];

        for(let i=0; i<connections.length; i++) {
            clean_connections.push(connections[i]);
            if (this.compareItems(connections[i][2], item)) {
                break;
            }
        }

        let key = clean_connections.length - 2;

        //console.log("clean_connection: " + JSON.stringify(clean_connections));

        while (key >= 0) {
            let remove = true;
            let it = clean_connections[key][2];
            for (let inner_key = key+1; inner_key<clean_connections.length; inner_key++) {
                //console.log("compare: " + JSON.stringify(it) + "=" + JSON.stringify(clean_connections[inner_key][0]) + "|| " + JSON.stringify(it) + "=" + JSON.stringify(clean_connections[inner_key][1]));
                if (this.compareItems(it, clean_connections[inner_key][0]) || this.compareItems(it, clean_connections[inner_key][1])) {
                    //console.log("not removed");
                    remove = false;
                }
            }

            if (remove) {
                clean_connections.splice(key,1);
                //console.log("removed " +key + JSON.stringify(clean_connections));
            }

            key--;
        };

        //console.log("clean_connection: " + JSON.stringify(clean_connections));

        for (let j=0; j<clean_connections.length;j++) {

            cost += clean_connections[j][0].reduce((a, b) => a + b, 0) + clean_connections[j][1].reduce((a, b) => a + b, 0);
        }

        return {'connections': clean_connections, 'cost': cost};
    }

    runSimulation() {

        if (!this.state.simulationRun) {
            this.setState({simulationRun: true}, this.displayState);
            let progress;
            let iterations;
            let algoritmArr =this.state.algoritm;
            let bestscore = 1000;
            let bestCost =1000;
            for (let index=0; index < algoritmArr.length; index++) {
                if (algoritmArr[index]) {
                    progress = this.state.progress;
                    iterations = this.state.iterations;
                    for (let i = progress[index]; i <= parseInt(iterations[index]); i++) {
                        this.simulation(index);
                        progress[index] = i;
                        //console.log("Run method:" + index + " iteration: " + i);
                    }
                    this.setState({progress: progress, simulationDidRun: true});
                }

                if (this.result[index].score < bestscore ||
                    (this.result[index].score === bestscore  && this.result[index].cost < bestCost)
                ) {
                    bestscore = this.result[index].score;
                    bestCost = this.result[index].cost;
                    this.props.usedItems(this.result[index].connections);
                }
            }

            // let bestScore = 1000;
            // let bestCost = 1000;
            // let bestRawConnections = [];
            //
            // Object.keys(this.state.result).map((tk,index) => {
            //     if (this.state.result[tk] && this.state.result[tk].connections !== []) {
            //
            //         if (this.state.result[tk].score <= bestScore && this.state.result[tk].cost < bestCost) {
            //             bestScore = this.state.result[tk].score;
            //             bestCost = this.state.result[tk].cost;
            //             bestRawConnections = [...this.state.result[tk].connections];
            //         }
            //     }
            // });
            // console.log("BestRawConnections");
            // console.log(JSON.stringify(bestRawConnections));

            //this.props.usedItems(bestRawConnections);
            this.setState({simulationRun: false}, this.displayState);
        }
    }

    // stopSimulation () {
    //     if (this.state.simulationRun) {
    //         this.resetSimulation();
    //     }
    // }

    selectAlgoritm(event, value) {
        let newValue = this.state.algoritm;
        newValue[value] = event.target.checked;

        this.setState({algoritm: newValue});

    }

    setIterations(event, value) {
        let newValue = this.state.iterations;
        newValue[value] = event.target.value;

        this.setState({iterations: newValue}, this.displayState);

    }

    displayState() {
        //console.log("State in tab3");
        //console.log(this.state);
    }

    render() {
        let className = this.props.display ? 'cell_body' : 'hide';
        let noDestinationItem = (this.checkDestinationItem(this.state.destinationItem)) ? 'hide' : 'cell_body';
        let noItems = (this.state.loadedItemsNumber.length === 0) ? 'cell_body' : 'hide';
        let noItemType = (this.state.itemTypeValue !== null) ? 'hide' : 'cell_body';

        //let readyToGo = 'cell_body boxes-in-line';
        let readyToGo = (noDestinationItem ==='hide' && noItems === 'hide' && noItemType === 'hide') ? 'cell_body' : 'hide';

        // const theme = createMuiTheme({
        //     palette: {
        //         primary: {main: '#2C0B0B'}, // Purple and green play nicely together.
        //         secondary: {main: '#720B0C'}, // This is just green.A700 as hex.
        //     },
        // });

        const MyCheckbox = withStyles(theme => ({
            root: {
                height: '26px',
                color: "#720B0C",
                "&:not(:checked)": {
                    color: "#720B0C"
                },
                "&:checked": {
                    color: "#720B0C"
                },
            },

        }))(Checkbox);

        const MyTextField = withStyles({
            root: {
                '& label': {
                    color: '#DDBEBD',
                },
                '& label.Mui-focused': {
                    color: '#720B0C',
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#720B0C',

                },
                '& .MuiOutlinedInput-root': {
                    color: "#DDBEBD",
                    width: "80px",
                    height: "30px",
                    '& fieldset': {
                        borderColor: '#DDBEBD',
                    },
                    '&:hover fieldset': {
                        borderColor: '#720B0C',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#720B0C',
                    },
                },
            },
        })(TextField);

        const MyButton = withStyles(theme => ({
            root: {
                height: '36px',
                margin: '19px',
                textAlign: 'center',
                backgroundColor: "#720B0C",
                '&:hover': {
                   backgroundColor: '#720B0C',
                }
            },

        }))(Button);

        let types = [];
        let best_connections = [];
        let best_item = [];
        let best_score = 1000;
        let best_cost = 1000;
        let bestDisplay = [];

        if (this.state.simulationDidRun) {
            Object.keys(this.state.result).map((tk,index) => {
                if (this.state.result[tk] && this.state.result[tk].connections !== []) {

                    types[tk] = this.state.result[tk].connections.map((v,k) => {
                        return (
                            <li key={k}>{this.Simulation.displayItem(this.state.BAZA[this.state.itemTypeValue], v[0])} + {this.Simulation.displayItem(this.state.BAZA[this.state.itemTypeValue], v[1])} = {this.Simulation.displayItem(this.state.BAZA[this.state.itemTypeValue], v[2])}</li>
                        )
                    });

                    if (this.state.result[tk].best !== []) {
                        bestDisplay[tk]= <LoadedItem
                            name={this.Simulation.displayItem(this.state.BAZA[this.state.itemTypeValue], this.state.result[tk].best)}
                            key='best' code='best'/>
                    }

                    if (this.state.result[tk].score < best_score ||
                        (this.state.result[tk].score === best_score && this.state.result[tk].cost < best_cost)) {
                        best_score = this.state.result[tk].score;
                        best_cost = this.state.result[tk].cost;
                        best_connections = types[tk];
                        best_item = bestDisplay[tk];

                    }
                }
                else {
                    types[tk] = "";
                }
                return true;
            });
        }

        return (
            <div className={className}>
                <div colSpan="3">
                    <div className={readyToGo}>
                        <table className="simulation-table">
                            <tbody>
                            <tr>
                                <td colSpan="3" className="top-row">
                                    Algorytmy:
                                </td>
                                <td rowSpan="4">
                                    <div className="simulation-tab">
                                        <MyButton variant="contained" color="secondary" onClick={this.startSimulation}>
                                            START
                                        </MyButton>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <FormControlLabel
                                    control={
                                        <MyCheckbox
                                            checked={this.state.algoritm[0]}
                                            onChange={(event) => this.selectAlgoritm(event, 0)}
                                            color="secondary"
                                            value="true"
                                        />
                                    }
                                    label="Algorytm 1"
                                />
                                </td>
                                <td className='iteration-counter'>
                                    <span>{this.state.progress[0]}</span> /
                                </td>
                                <td>
                                    <MyTextField onChange={(event) => this.setIterations(event, 0)} id="iterations1" label="Iteracje" variant="outlined" value={this.state.iterations[0]} size="small"/>
                                </td>
                                <td>

                                </td>
                            </tr>

                            <tr>
                                <td>
                                <FormControlLabel
                                    control={
                                        <MyCheckbox
                                            checked={this.state.algoritm[1]}
                                            onChange={(event) => this.selectAlgoritm(event, 1)}
                                            color="secondary"
                                            value="true"
                                        />
                                    }
                                    label="Algorytm 2"
                                />
                                </td>
                                <td className='iteration-counter'>
                                    <span>{this.state.progress[1]}</span> /
                                </td>
                                <td>
                                    <MyTextField onChange={(event) => this.setIterations(event, 1)} id="iterations2" label="Iteracje" variant="outlined" value={this.state.iterations[1]} size="small"/>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                <FormControlLabel
                                    control={
                                        <MyCheckbox
                                            checked={this.state.algoritm[2]}
                                            onChange={(event) => this.selectAlgoritm(event, 2)}
                                            color="secondary"
                                            value="true"
                                        />
                                    }
                                    label="Algorytm 3"
                                />
                                </td>
                                <td className='iteration-counter'>
                                    <span>{this.state.progress[2]}</span> /
                                </td>
                                <td>
                                    <MyTextField onChange={(event) => this.setIterations(event, 2)} id="iterations3" label="Iteracje" variant="outlined" value={this.state.iterations[2]} size="small"/>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td colSpan='4' className={(!this.state.simulationDidRun) ? 'hide': 'top-row'}><h3>Results</h3></td>
                            </tr>
                            <tr>
                                <td colSpan='4' className={(!this.state.simulationDidRun) ? 'hide': 'top-row'}>
                                    <div className='result-groups'>
                                        Best Algorytm: <br/>
                                        Score: {best_score} <br/>
                                        Cost: {best_cost} <br/>
                                        Item: {best_item} <br/>
                                        <ul className="item_prop3">
                                            {best_connections}
                                        </ul>
                                    </div>
                                    <div className={(!this.state.algoritm[0]) ? 'hide': 'result-groups'}>
                                        Algorytm 1: <br/>
                                        Score: {this.state.result[0].score} <br/>
                                        Cost: {this.state.result[0].cost} <br/><br/>
                                        Item: {bestDisplay[0]} <br/>
                                        <ul className="item_prop3">
                                            {types[0]}
                                        </ul>
                                    </div>
                                    <div className={(!this.state.algoritm[1]) ? 'hide': 'result-groups'}>
                                        Algorytm 2: <br/>
                                        Score: {this.state.result[1].score} <br/>
                                        Cost: {this.state.result[1].cost} <br/><br/>
                                        Item: {bestDisplay[1]} <br/>
                                        <ul className="item_prop3">
                                            {types[1]}
                                        </ul>
                                    </div>
                                    <div  className={(!this.state.algoritm[2]) ? 'hide': 'result-groups'}>
                                        Algorytm 3: <br/>
                                        Score: {this.state.result[2].score} <br/>
                                        Cost: {this.state.result[2].cost} <br/><br/>
                                        Item: {bestDisplay[2]} <br/>
                                        <ul className="item_prop3">
                                            {types[2]}
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tab3;

