import React from 'react';
import Radio from '@material-ui/core/Radio';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


class ServerType extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            value: this.props.server
        }

        this.selectType = this.selectType.bind(this);
    }

    selectType(event) {
        //this.props.func(parseInt(event.target.id))
        //alert(event.target.value);
        this.setState({value:event.target.value});
        this.props.func(event.target.value);
    }

    render() {

        const MyRadio = withStyles(theme => ({
            root: {
                height: '26px',
                "&:not($checked)": {
                    color: "#2C0B0B"
                },
                checked: {
                    color: "#2C0B0B"
                },
            },

        }))(Radio);

        const MyFormControlLabel = withStyles({
            root: {
                height: '26px'
            }
        })(FormControlLabel);

        const theme = createMuiTheme({
            palette: {
                //primary: {main: '#2C0B0B'}, // Purple and green play nicely together.
                secondary: {main: '#720B0C'}, // This is just green.A700 as hex.
            },
        });

        //console.log(this.state);
        return (
            <MuiThemeProvider theme={theme}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="position" name="position" value={this.state.value} onChange={(event) => this.selectType(event)} row>
                        <MyFormControlLabel
                            value="r15"
                            control={<MyRadio />}
                            label="r15"
                            labelPlacement="start"
                        />
                        <MyFormControlLabel
                            value="r17"
                            control={<MyRadio />}
                            label="r17"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </FormControl>
            </MuiThemeProvider>
        );
    }
}

export default ServerType;
