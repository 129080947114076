import React from "react";

class LoadType extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    selectType(event) {
        //console.log(event.target)
        this.props.func(event.target.id)
    }

    render() {
        let className = this.props.selected ? 'selected' : '';
        return (
                <li className={className} onClick={(event) => this.selectType(event)} id={this.props.id}>
                    <span onClick={(event) => this.selectType(event)} id={this.props.id}> {this.props.name}</span>
                </li>
        );
    }
}

export default LoadType;
