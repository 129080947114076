import React from "react";

class ItemQuality extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    selectType(event) {
        this.props.func(event.target.id)
    }

    render() {
        let className = this.props.selected ? 'selected' : '';
        return (
            <li className={className}>
                <span onClick={(event) => this.selectType(event)} id={this.props.code}>{this.props.name}</span>
            </li>
        );
    }
}

export default ItemQuality;
