import React from "react";

class LoadedItem extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        //let className = this.props.selected ? 'selected' : '';
        return (
            <li className={(this.props.hightlight) ? 'selected': ''}>
                <span id={this.props.code}> {this.props.name}</span>
            </li>
        );
    }
}

export default LoadedItem;
