import React from "react";

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    selectType(event) {
        this.props.func(parseInt(event.target.id))
    }

    render() {
        let className = this.props.selected ? 'selection_tab selected' : 'selection_tab';
        return (
            <div className={className} onClick={(event) => this.selectType(event)} id={this.props.code}>{this.props.name}</div>
        );
    }
}

export default Tabs;

