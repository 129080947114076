//import React from 'react';

class Simulation  {

    laczenie (P1, P2, BAZA, itemTypeValue, server) {
        if (server ==='r17') {
            return this.laczenie_r17 (P1, P2, BAZA, itemTypeValue);
        } else {
            return this.laczenie_r15 (P1, P2, BAZA, itemTypeValue);
        }
    }

    laczenie_r17 (P1, P2, BAZA, itemTypeValue) {
        /*
        1+1 = 1
        1+2 = 3  2+2 = 2
        1+3 = 3  2+3 = 4
        1+4 = 4  2+4 = 4
        1+5 = 4  2+5 = 5
        1+6 = 5  2+6 = 5
        1+7 = 5  2+7 = 6
        1+8 = 6  2+8 = 6
        1+9 = 6  2+9 = 7
        1+10= 7  2+10= 7
        1+11= 7  2+11= 8

        // a1+a2+3 / 2

        2 + 12 + 3 = 17
        */
        //tapped at 32


        let result = [];

        for(let i=0; i<3; i++) {
            let var1 = P1[i];
            let var2 = P2[i];
            let n;
            if (var1 === var2) {
                n = var1;
            } else {
                let count;
                if (i===0) count = BAZA[itemTypeValue]['PX'].length;
                if (i===1) count = BAZA[itemTypeValue]['P'].length;
                if (i===2) count = BAZA[itemTypeValue]['SX'].length;

                let tap = (count-1)*2;
                let suma = var1 + var2 + 3;
                let minus = Math.max(0, suma - tap);
                n = (Math.floor(Math.min(suma, tap)/2) - minus);
            }
            result[i] = n;
        }

        //Custom logic for Amulets
        //r17 amulety
        //0+1 = 1  1+2 = 4  2+3 = 3  3+4 = 0
        //0+2 = 1  1+3 = 4  2+4 = 3
        //0+3 = 4  1+4 = 3
        //0+4 = 4

        if (itemTypeValue === 'A') {
            if (((P1[1] === 0 && P2[1] === 1) || (P1[1] === 1 && P2[1] === 0))) result[1] = 1;
            else if (((P1[1] === 0 && P2[1] === 2) || (P1[1] === 2 && P2[1] === 0))) result[1] = 1;
            else if (((P1[1] === 0 && P2[1] === 3) || (P1[1] === 3 && P2[1] === 0))) result[1] = 4;
            else if (((P1[1] === 0 && P2[1] === 4) || (P1[1] === 4 && P2[1] === 0))) result[1] = 4;

            else if (((P1[1] === 1 && P2[1] === 2) || (P1[1] === 2 && P2[1] === 1))) result[1] = 4;
            else if (((P1[1] === 1 && P2[1] === 3) || (P1[1] === 3 && P2[1] === 1))) result[1] = 4;
            else if (((P1[1] === 1 && P2[1] === 4) || (P1[1] === 4 && P2[1] === 1))) result[1] = 3;

            else if (((P1[1] === 2 && P2[1] === 3) || (P1[1] === 3 && P2[1] === 2))) result[1] = 3;
            else if (((P1[1] === 2 && P2[1] === 4) || (P1[1] === 4 && P2[1] === 2))) result[1] = 3;

            else if (((P1[1] === 3 && P2[1] === 4) || (P1[1] === 4 && P2[1] === 3))) result[1] = 1;

        }
        //console.log("type:" + itemTypeValue + "p1:" + JSON.stringify(P1) + " p2:" + JSON.stringify(P2) + " res:" + JSON.stringify(result))

        return result;
    }

    laczenie_r15 (P1, P2, BAZA, itemTypeValue) {
        /*
        1+1 = 1
        1+2 = 3  2+2 = 2
        1+3 = 3  2+3 = 4
        1+4 = 4  2+4 = 4
        1+5 = 4  2+5 = 5
        1+6 = 5  2+6 = 5
        1+7 = 5  2+7 = 6
        1+8 = 6  2+8 = 6
        1+9 = 6  2+9 = 7
        1+10= 7  2+10= 7
        1+11= 7  2+11= 8

        // a1+a2+3 / 2

        2 + 12 + 3 = 17
        */
        //tapped at 32

        let result = [];

        for(let i=0; i<3; i++) {
            let var1 = P1[i];
            let var2 = P2[i];
            let n;
            if (var1 === var2) {
                n = var1;
            } else {
                let count;
                if (i===0) count = BAZA[itemTypeValue]['PX'].length;
                if (i===1) count = BAZA[itemTypeValue]['P'].length;
                if (i===2) count = BAZA[itemTypeValue]['SX'].length;

                let tap = (count-1)*2;
                let suma = var1 + var2 + 3;
                let minus = Math.max(0, suma - tap);
                n = (Math.floor(Math.min(suma, tap)/2) - minus);
            }
            result[i] = n;
        }
        //helm + czapka to maska - fuck logic
        if (itemTypeValue === 'H' && ((P1[1] === 2 && P2[1] === 0) || (P1[1] === 0 && P2[1] === 2))) {
            result[1] = 3;
        }
        return result;
    }

    sum (item) {
        let sum = 0;

        for (let k in item) {
            sum +=item[k];
        }
        return sum;
    }

    displayItem(BAZA, item) {
        return BAZA['PX'][item[0]] + '('+item[0]+') ' +BAZA['P'][item[1]] + '('+item[1]+') ' + BAZA['SX'][item[2]]+'('+item[2]+')';
    }

    goodConnections(loadedItemsNumber, BAZA, itemTypeValue, server) {
        let good_connections = [];
        good_connections['type1'] = [];
        good_connections['type2'] = [];
        good_connections['type3'] = [];
        good_connections['type4'] = [];
        //console.log("GOOD CONNECTION CALCULATION");
        for (let i = 0; i < loadedItemsNumber.length; i++) {
            let itemA = loadedItemsNumber[i];
            for (let j = i+1; j < loadedItemsNumber.length; j++) {
                let itemB = loadedItemsNumber[j];

                    //echo("\n\nTESTING:".display($BAZA, $item, true).' + '.display($BAZA, $items[$i], true));
                    if (((itemA[0]+1) === itemB[0] || (itemA[0]-1) === itemB[0]) && ((itemA[2]+1) === itemB[2] || (itemA[2]-1) === itemB[2])) {
                        let res = this.laczenie(itemA, itemB, BAZA, itemTypeValue, server);
                        good_connections['type1'].push([itemA, itemB, res]);
                    }

                    if (((itemA[0]+1) === itemB[0] || (itemA[0]-1) === itemB[0]) && ((itemA[2]+3) === itemB[2] || (itemA[2]-3) === itemB[2])) {
                        let res = this.laczenie(itemA, itemB, BAZA, itemTypeValue, server);
                        good_connections['type2'].push([itemA, itemB, res]);
                    }

                    if (((itemA[0]+3) === itemB[0] || (itemA[0]-3) === itemB[0]) && ((itemA[2]+1) === itemB[2] || (itemA[2]-1) === itemB[2])) {
                        let res = this.laczenie(itemA, itemB, BAZA, itemTypeValue, server);
                        good_connections['type2'].push([itemA, itemB, res]);
                    }

                    if (((itemA[0]+3) === itemB[0] ) && ( (itemA[2]-3) === itemB[2])) {
                        let res = this.laczenie(itemA, itemB, BAZA, itemTypeValue, server);
                        good_connections['type3'].push([itemA, itemB, res]);
                    }

                    if (((itemA[0]-3) === itemB[0] ) && ( (itemA[2]+3) === itemB[2])) {
                        let res = this.laczenie(itemA, itemB, BAZA, itemTypeValue, server);
                        good_connections['type3'].push([itemA, itemB, res]);
                    }

                    if ((itemA[0]+itemB[0])%2!==0 && (itemA[2]+itemB[2])%2!==0) {
                        let res = this.laczenie(itemA, itemB, BAZA, itemTypeValue, server);
                        if (this.sum(res) > this.sum(itemA) && this.sum(res) > this.sum(itemB)) {
                            good_connections['type4'].push([itemA, itemB, res]);
                        }
                    }

            }
        }

        //this.setState({goodConnections: good_connections}, this.displayState);
        // console.log(itemTypeValue);
        // console.log(loadedItemsNumber);
        // console.log(good_connections);

        return good_connections;
    }

    quality(BAZA, GOAL, P) {
        let quality = 0;
        let iTOindex = ['PX', 'P', 'SX'];
        let multiplier = 1;
        for (let i=0; i<3; i++) {
            if (i === 1)
                multiplier = 1;
            else
                multiplier = 1;

            if (GOAL[i] !== null) {
                quality += (multiplier * Math.abs((GOAL[i] - P[i])));
            } else {
                //if no goal is set we aim for highest quality
                quality += Math.abs((BAZA[iTOindex[i]].length - 1) - P[i]);
            }
        }

        //print_r($GOAL);print_r($P);echo("q:".$quality);
        //die("die");
        return quality;
    }

}


export default Simulation;
