import React from "react";

class Tab2 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    // selectType(event) {
    //     this.props.func(parseInt(event.target.id))
    // }

    displayItem(item) {
        return this.props.BAZA[this.props.itemTypeValue]['PX'][item[0]] + '('+item[0]+') ' +this.props.BAZA[this.props.itemTypeValue]['P'][item[1]] + '('+item[1]+') ' + this.props.BAZA[this.props.itemTypeValue]['SX'][item[2]]+'('+item[2]+')';
    }

    render() {
        let className = 'hide';
        let types=[];

        if (this.props.goodConnections) {
            Object.keys(this.props.goodConnections).map((tk,index) => {
                if (this.props.goodConnections[tk]) {
                    className = (this.props.display) ? 'rTableCell cell_body colspan' : 'hide';
                    types[tk] = this.props.goodConnections[tk].map((v,k) => {
                        return (
                            <li key={k}>{this.displayItem(v[0])} + {this.displayItem(v[1])} = {this.displayItem(v[2])}</li>
                        )
                    });
                }
                else {
                    types[tk] = "";
                }
                return true;
            });

        }

        return (
            <div className={className}>
                <div>
                    TYP 1 (pref i sufix up):
                    <ul className="item_prop3">
                        {types['type1']}
                    </ul>
                    TYP 2 (pol krzyzowe):
                    <ul className="item_prop3">
                        {types['type2']}
                    </ul>
                    TYP 3 (krzyzowe):
                    <ul className="item_prop3">
                        {types['type3']}
                    </ul>
                    TYP 4 (pozostale):
                    <ul className="item_prop3">
                        {types['type4']}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Tab2;

