import React from 'react';
//import logo from './logo.svg';
import './App.css';
import LoadItems from './component/LoadItems';
import LoadedItem from './component/LoadedItem';
import ItemType from './component/ItemType';
import ItemQuality from './component/ItemQuality';
import ServerType from './component/ServerType';

import ITEM from './data/item'
import {BAZA15, BAZA_REV15} from './data/items-r15.js'
import {BAZA17, BAZA_REV17} from './data/items-r17.js'
import Tabs from "./component/Tabs";
import Tab1 from "./component/Tab1";
import Tab2 from "./component/Tab2";
import Tab3 from "./component/Tab3";
import Simulation from "./component/Simulation";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        itemTypeValue: null,
        itemQuality: "l",
        allItems: "",
        loadedItems: [],
        loadedItemsNumber: [],
        tabSelected: 0,
        destinationItem: [-1,-1,-1],
        goodConnections: [],
        server: "r15",
        BAZA: BAZA15,
        BAZA_REV: BAZA_REV15,
        usedItems: []
    };
    this.itemBaza = null;
    this.itemType = this.itemType.bind(this);
    this.itemQuality = this.itemQuality.bind(this);
    this.loadItems = this.loadItems.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.selectServer = this.selectServer.bind(this);
    this.selectDestinationItem = this.selectDestinationItem.bind(this);
    this.hightlightLoadedItems = this.hightlightLoadedItems.bind(this);

    document.title = "RAKU laczenia";

    this.quality = [
        {k:"z",v:"zwykle"},
        {k:"l",v:"legendarne"},
    ];

    this.tabs = [
        {id:0, name: "Przedmiot docelowy"},
        {id:1, name: "Sugerowane łączenia"},
        {id:2, name: "Symulacja"}
    ];
    this.Simulation = new Simulation();
  }

    checkDestinationItem() {
        const values = Object.values(this.state.destinationItem);
        if (values.length === 0) return false;
        for (const val of values) {
            if (val === -1) {
                return false;
            }
        }
        return true;
    }

  selectServer(server) {
      this.setState({server: server}, this.displayState);
      if (server === "r15") {
          this.setState({BAZA: BAZA15});
          this.setState({BAZA_REV: BAZA_REV15}, this.inputDataChange);
      }

      if (server === "r17") {
          this.setState({BAZA: BAZA17});
          this.setState({BAZA_REV: BAZA_REV17}, this.inputDataChange);
      }
  }

  hightlightLoadedItems(usedItems) {
      let usedItemsArr = [];
      for (let i=0; i < usedItems.length; i++) {
          let conn = usedItems[i];
          let id1 = conn[0][0]+'-'+conn[0][1]+'-'+conn[0][2];
          let id2 = conn[1][0]+'-'+conn[1][1]+'-'+conn[1][2];
          usedItemsArr[id1]=1;
          usedItemsArr[id2]=1;
      }
      for (let i=0; i < usedItems.length; i++) {
          let conn = usedItems[i];
          let id = conn[2][0]+'-'+conn[2][1]+'-'+conn[2][2];
          usedItemsArr[id]=0;

      }
      this.setState({usedItems: usedItemsArr});
      //console.log(usedItemsArr);
  }

  selectTab(tabSelected) {
      this.setState({tabSelected: tabSelected}, this.displayState);
  }

  selectDestinationItem(destinationItem) {
      this.setState({destinationItem: destinationItem}, this.displayState);
  }

  loadItems(itemsText) {
      //console.log("parent: " + itemsText);
      this.setState({allItems: itemsText}, this.inputDataChange);
  }

  itemType(itemType) {
      //console.log("parent itemType: " + itemType);
      this.setState({itemTypeValue: itemType}, this.inputDataChange);
  }

  itemQuality(itemQ) {
      //console.log("parent itemQuality: " + itemQ);
      this.setState({itemQuality: itemQ}, this.inputDataChange);
  }

  inputDataChange() {
      //console.log("inputDataChange callde");
      //Reload Items when all 3 data in place
      if (this.state.itemTypeValue !== null && this.state.itemQuality !== "") {
          //console.log("WILL LOAD ITEMS NOW");
          let loadedItemsNumber = this.loadInputItems();
          let good_connections = this.Simulation.goodConnections(loadedItemsNumber, this.state.BAZA, this.state.itemTypeValue, this.state.server);
          this.setState({goodConnections: good_connections}, this.displayState);
      }
  }

  displayState() {
      //console.log(this.state);
  }

  render () {
      const basicItems = ITEM.map(it => {
          return (
              <ItemType selected={(this.state.itemTypeValue === it.k)} func={this.itemType} name={it.v} key={it.k} id={it.k} />
          )
      });

      const itemQuality = this.quality.map(it => {
          return (
              <ItemQuality selected={(this.state.itemQuality === it.k)} func={this.itemQuality} name={it.v} key={it.k} code={it.k} />
          )
      });

      const loadedItems = this.state.loadedItems.map((v, k) => {
          let itemNumeric = this.state.loadedItemsNumber[k];
          let id = itemNumeric[0] + '-' + itemNumeric[1] + '-' + itemNumeric[2];
          //console.log(id);
          return (
              <LoadedItem name={v} key={k} code={k} hightlight={(this.state.usedItems[id] === 1)? 1 : 0}/>
          )
      });

      const tabs = this.tabs.map(it => {
          return (
              <Tabs selected={(this.state.tabSelected === it.id)} func={this.selectTab} name={it.name} key={it.id} code={it.id} />
          )
      });
      const tab1Items = this.state.BAZA[this.state.itemTypeValue];
      //console.log(tab1Items);
      return (
          <div className="container">
              <div className='fill-top'></div>
              <div className='fill-left'></div>
              <div className='fill-right'></div>
              <div className="input-items">
                <LoadItems func={this.loadItems}/>
              </div>
              <div className="loaded-items">
                  <ul className="item_prop1">
                    {loadedItems}
                  </ul>
              </div>
              <div className="item-type">
                  <ul className="item">
                      {basicItems}
                  </ul>
              </div>

              <div className="item-quality">
                  <ul className="item">
                      {itemQuality}
                  </ul>
                  <ServerType func={this.selectServer} server={this.state.server} />
              </div>

              {tabs}

              <div className='info'>
                <div className={(this.state.allItems === '') ? 'cell_body' : 'hide'}>1) Wklej Przedmioty ze zbrojowni</div>
                <div className={(this.state.itemTypeValue === null) ? 'cell_body' : 'hide'}>2) Wybierz rodzaj przedmiotu</div>
                <div className={(this.checkDestinationItem()) ? 'hide' : ' cell_body'}>3) Wybierz przedmiot docelowy</div>
              </div>

              <div className="tab-content">
              <Tab1 func={this.selectDestinationItem} item={tab1Items} display={(this.state.tabSelected === 0)}/>
              <Tab2 BAZA={this.state.BAZA} itemTypeValue={this.state.itemTypeValue} goodConnections={this.state.goodConnections} display={(this.state.tabSelected === 1)}/>
              <Tab3 server={this.state.server} usedItems={this.hightlightLoadedItems} loadedItemsNumber={this.state.loadedItemsNumber} destinationItem={this.state.destinationItem} BAZA={this.state.BAZA} itemTypeValue={this.state.itemTypeValue} display={(this.state.tabSelected === 2)}/>
              </div>


              <div className='donate'>
                  Podoba Ci sie? Postaw piwo, albo dwa tutaj ->
                  <form className="boxes-in-line donate" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                    <input type="hidden" name="cmd" value="_donations" />
                    <input type="hidden" name="business" value="jacek.raczkiewicz@gmail.com" />
                    <input type="hidden" name="currency_code" value="PLN" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_PL/i/scr/pixel.gif" width="1" height="1" />
                  </form>
                  <br/>
                  email: jacek.raczkiewicz@gmail.com lub raku na serwerze r15
              </div>

          </div>
      )
    // return (
    //     <div className="rTableRow">
    //         <div className="boxes-in-line">
    //           <LoadItems func={this.loadItems}/>
    //           <br />
    //             <ul className="item">
    //                 {basicItems}
    //             </ul>
    //           <br />
    //           <br />
    //             <div>
    //                 <ul className="item">
    //                     {itemQuality}
    //                 </ul>
    //                 <ServerType func={this.selectServer} server={this.state.server} />
    //
    //             </div>
    //             <div>
    //                 <br />
    //                 <table className="bottom-table">
    //                     <tbody>
    //                         <tr>
    //                         {tabs}
    //                         </tr>
    //
    //                         <tr>
    //                             <td colSpan='3'>
    //                         <div className={(this.state.allItems === '') ? 'cell_body' : 'hide'}>1) Wklej Przedmioty ze zbrojowni</div>
    //                         <div className={(this.state.itemTypeValue === null) ? 'cell_body' : 'hide'}>2) Wybierz rodzaj przedmiotu</div>
    //                         <div className={(this.checkDestinationItem()) ? 'hide' : 'cell_body'}>3) Wybierz przedmiot docelowy</div>
    //                             </td>
    //                         </tr>
    //
    //                         <Tab1 func={this.selectDestinationItem} item={tab1Items} display={(this.state.tabSelected === 0)}/>
    //                         <Tab2 BAZA={this.state.BAZA} itemTypeValue={this.state.itemTypeValue} goodConnections={this.state.goodConnections} display={(this.state.tabSelected === 1)}/>
    //                         <Tab3 usedItems={this.hightlightLoadedItems} loadedItemsNumber={this.state.loadedItemsNumber} destinationItem={this.state.destinationItem} BAZA={this.state.BAZA} itemTypeValue={this.state.itemTypeValue} display={(this.state.tabSelected === 2)}/>
    //
    //                     </tbody>
    //                 </table>
    //             </div>
    //             <div className='donate'>
    //                 <br/>Podoba Ci sie? Postaw piwo, albo dwa tutaj ->
    //                 <form className="boxes-in-line donate" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
    //                     <input type="hidden" name="cmd" value="_donations" />
    //                     <input type="hidden" name="business" value="jacek.raczkiewicz@gmail.com" />
    //                     <input type="hidden" name="currency_code" value="PLN" />
    //                     <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
    //                     <img alt="" border="0" src="https://www.paypal.com/en_PL/i/scr/pixel.gif" width="1" height="1" />
    //                 </form>
    //                 <br/>
    //                 email: jacek.raczkiewicz@gmail.com lub raku na serwerze r15
    //             </div>
    //         </div>
    //         <div className="boxes-in-line">
    //             <ul className="item_prop1">
    //                 {loadedItems}
    //             </ul>
    //         </div>
    //     </div>
    // )
  }

    loadInputItems() {
        let allItemsArr = this.state.allItems.split("\n");
        let loadedItems = [];
        let loadedItemsNumber = [];
        //console.log(allItemsArr);

        for (let i = 0; i < allItemsArr.length; i++) {
            if (allItemsArr[i].indexOf("SPRZEDAJ") === -1 && allItemsArr[i].indexOf("EKWIPUJ") === -1) {
                if ((this.state.itemQuality === 'z' && allItemsArr[i].indexOf("Legendarn") === -1) || (this.state.itemQuality === 'l' && allItemsArr[i].indexOf("Legendarn") !== -1)) {
                    //good item matching selected target
                    let item = allItemsArr[i];

                    item = item.replace('Legendarny', '');
                    item = item.replace('Legendarna', '');
                    item = item.replace('Legendarne', '');
                    item = item.replace('Dobry', '');
                    item = item.replace('Dobra', '');
                    item = item.replace('Dobre', '');
                    item = item.replace('Doskonały', '');
                    item = item.replace('Doskonała', '');
                    item = item.replace('Doskonałe', '');
                    item = item.replace('Zbroja warstwowa', 'Zbroja_warstwowa');
                    item = item.replace('Pełna zbroja', 'Pełna_zbroja');
                    item = item.replace('Piła łańcuchowa', 'Piła_łańcuchowa');
                    item = item.replace('Miecz Dwuręczny', 'Miecz_Dwuręczny');
                    item = item.replace('Topór Dwuręczny', 'Topór_Dwuręczny');
                    item = item.replace('Pięść Niebios', 'Pięść_Niebios');
                    item = item.replace(/\(\+\d\)/g, '');

                    item = item.trim();
                    console.log(item);

                    let itemArr = item.split(" ");
                    if (itemArr[3]) {
                        itemArr[2] = itemArr[2] + ' ' + itemArr[3];
                        itemArr.splice(3,1);
                    }

                    //console.log(itemArr[1] + '  ' + this.selectedItem);
                    //console.log(JSON.stringify(itemArr));
                    //console.log("using item type:" + this.state.itemTypeValue +" |"+ itemArr[1] +"| " + BAZA[this.state.itemTypeValue]['P']);
                    if (-1 !== this.state.BAZA[this.state.itemTypeValue]['P'].indexOf(itemArr[1]) && itemArr.length === 3) {
                        //console.log("matched");
                        //item = itemArr[0]+'('+this.state.BAZA_REV[this.state.itemTypeValue]['PX'][itemArr[0]]+') ' + itemArr[1]+'('+this.state.BAZA_REV[this.state.itemTypeValue]['P'][itemArr[1]]+') ' + itemArr[2]+'('+this.state.BAZA_REV[this.state.itemTypeValue]['SX'][itemArr[2]]+') ';
                        //loadedItems.push(item);
                        let itemNumeric = [];
                        itemNumeric[0] = this.state.BAZA_REV[this.state.itemTypeValue]['PX'][itemArr[0]];
                        itemNumeric[1] = this.state.BAZA_REV[this.state.itemTypeValue]['P'][itemArr[1]];
                        itemNumeric[2] = this.state.BAZA_REV[this.state.itemTypeValue]['SX'][itemArr[2]];

                        //itemMapping[itemNumeric[0] + '-' + itemNumeric[1] + '-' + itemNumeric[2]] = allItemsArr[i];

                        loadedItemsNumber.push(itemNumeric);
                    }
                }
            }
        }

        loadedItemsNumber.sort(function(a, b) {
            let qa = a.reduce((a, b) => a + b, 0);
            let qb = b.reduce((a, b) => a + b, 0);
           if (qa > qb) {
               return 1
           }
           if (qa < qb) {
               return -1
           }
           return 0;
        });

        for (let i = 0; i < loadedItemsNumber.length; i++) {
            let itemArr = loadedItemsNumber[i];
            let item = this.state.BAZA[this.state.itemTypeValue]['PX'][itemArr[0]]+'('+itemArr[0]+') ' + this.state.BAZA[this.state.itemTypeValue]['P'][itemArr[1]]+'('+itemArr[1]+') ' + this.state.BAZA[this.state.itemTypeValue]['SX'][itemArr[2]]+'('+itemArr[2]+')';
            loadedItems.push(item);
        }

        this.setState({loadedItems: loadedItems});
        this.setState({loadedItemsNumber: loadedItemsNumber});
        return loadedItemsNumber;
    }

}

export default App;
